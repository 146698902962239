import axios from 'axios';

const questAPI = axios.create({
    baseURL: 'https://oi6toik1i3.execute-api.ap-northeast-2.amazonaws.com',
    headers: {
        'Content-type': 'application/json'
    }
});

// ONLY for debugging purpose
// IGNORE IT!!
const deleteQuestByCode = async (quest_code: string) => {
    const response = await questAPI.delete<any>(`/quests/${quest_code}`);
    return response.data;
};

// gets all quests and not really going to be used for current projects
// important for checking if the user exists in the questsDB using 
// any attributes by iterating through all the quests
// IGNORE IT!! (may be)
const getQuestAll = async () => {
    const response = await questAPI.get('/quests');
    return response.data;
};

// gets all quest information by using the 
// PARTITION_KEY: quest_code and SORT_KEY: quest_id
// Important for:
// checking if the quest_code and quest_id exists (?)
// checking if the quest_code and quest_id matches
// getting the the reviews, starts, no_players if already input to update it
// getting no_restarted to track how many times the user played the quest
// get no_finished to tack how many times the user finished the game
// and etc...
const getQuestByCode = async (quest_code: string, quest_id: string) => {
    const response = await questAPI.get(`/quests/${quest_code}/${quest_id}`);
    return response.data;
};

// put the quest_code and quest_id into the questsDB
// when the user starts the game
const updateQuest = async (quest_code: string, quest_id: string) => {
    const response = await questAPI.put(`/quests/${quest_code}/${quest_id}`);
    return response.data;
};

const questApi = {
    deleteQuestByCode,
    getQuestAll,
    getQuestByCode,
    updateQuest,
};

export default questApi;