import React, { useState, useEffect } from "react";

import PlatformStyles from "../styles/PlatformStyles";

import { useNavigate } from "react-router-dom";
import gameHiddenLogo from "../assets/svg/gameHiddenLogo.svg";

import gameLocation_eoeun from "../assets/svg/gameLocation_eoeun.svg";
import gameLocation_mannyeon from "../assets/svg/gameLocation_mannyeon.svg";
import gameLocation_seochon from "../assets/svg/gameLocation_Seochon.svg";

import buttonVisitPlace from "../assets/svg/button_visitplace.svg";

import placeEo from "../assets/svg/place_eoeun.svg";
import placeMn from "../assets/svg/place_mn.svg";

import placeIconEo from "../assets/svg/place_icons_eoeun.svg";
import placeIconMn from "../assets/svg/place_icons_mn.svg";

import gameLogoImg_ryunge from "../assets/svg/gameLogo_ryunge.svg";
import gameLogoImg_ryungePrequal from "../assets/svg/gameLogo_ryungePrequal.svg";
import gameLogoImg_MNOnline from "../assets/svg/gameLogo_MNOnline.svg";
import gameLogoImg_MNOff1 from "../assets/svg/gameLogo_MNOff1.svg";
import gameLogoImg_MNOff2 from "../assets/svg/gameLogo_MNOff2.svg";
import gameLogoImg_MNOff3 from "../assets/svg/gameLogo_MNOff3.svg";
import gameLogoImg_MNOff4 from "../assets/svg/gameLogo_MNOff4.svg";
import gameLogoImg_Abandoned from "../assets/svg/Thisstation_abandoned_image.svg";
import gameLogoImg_Basecamp from "../assets/svg/Thisstation_basecamp_image.svg";

import gameQuestPack from "../assets/svg/questPack.svg";

import gameType_Online from "../assets/svg/Online.svg";
import gameType_Offline from "../assets/svg/Offline.svg";

import Contact from "./Misc/Contact";
import Footer from "./Misc/Footer";

import gameBtmBtnHome from "../assets/svg/bottomButtonHome.svg";
import UpLoadGames from "./UpLoadCodes";

const {
  Container,
  GameLogoDiv,
  GameLogoImg,
  HomeDiv,
  HomeImgDiv,
  GameLocationImg,
  GameHomeImgDiv,
  GameHomeImg,
  GameTitleContDiv,
  GameTitleDiv,
  GameTitleText,
  GameMaterialsDiv,
  GameMaterialsIcon,
  GameType,
  GameTypeImg,
  GraySpan,
  MiscellaneousDiv,
  BottomBtnHome,
  BtnImg,
  EoTxtKor,
  EoTxtEng,
  MnTxtKor,
  MnTxtEng,
  JustDiv,
  Padding25,
} = PlatformStyles;

const pagesData = [
  {
    id: 0,
    quest_id: "YUEO01OFAA",
    firstLocation: true,
    gameLocation: gameLocation_eoeun,
    gameLogoImg: gameLogoImg_ryunge,
    textKorean: "안녕마을 실종사건",
    textEnglish: "Annyeong Town Mystery",
    questPack: true,
    gameType: gameType_Offline,
    homepage: "ryunge",
    published: true,
  },
  {
    id: 1,
    quest_id: "YUEO02ONAB",
    firstLocation: false,
    gameLocation: gameLocation_eoeun,
    gameLogoImg: gameLogoImg_ryungePrequal,
    textKorean: "장씨와 신비한 돌",
    textEnglish: "Mystical Stone of Eoeun",
    questPack: false,
    gameType: gameType_Online,
    homepage: "ryungePrequal",
    published: true,
  },
  {
    id: 2,
    quest_id: "SUMN01ONAC",
    firstLocation: true,
    gameLocation: gameLocation_mannyeon,
    gameLogoImg: gameLogoImg_MNOnline,
    textKorean: "만년용사의 자격증명",
    textEnglish: "Way of the Hero",
    questPack: false,
    gameType: gameType_Online,
    homepage: "mnOnline",
    published: true,
  },
  {
    id: 3,
    quest_id: "SUMN02OFAD",
    firstLocation: false,
    gameLocation: gameLocation_mannyeon,
    gameLogoImg: gameLogoImg_MNOff1,
    textKorean: "출동! 만년용사!",
    textEnglish: "Go! Mannyeon Hero!",
    questPack: false,
    gameType: gameType_Offline,
    homepage: "mnOff1",
    published: true,
  },
  {
    id: 4,
    quest_id: "SUMN03OFAE",
    firstLocation: false,
    gameLocation: gameLocation_mannyeon,
    gameLogoImg: gameLogoImg_MNOff2,
    textKorean: "용사와 은빛여울",
    textEnglish: "Hero and the Silver Ford",
    gameType: gameType_Offline,
    homepage: "mnOff2",
    published: true,
  },
  // {
  //   id: 5,
  //   quest_id: "JNSC02OFAI",
  //   firstLocation: true,
  //   gameLocation: gameLocation_seochon,
  //   gameLogoImg: gameLogoImg_Abandoned,
  //   textKorean: "이번 역은.. 폐역사편",
  //   textEnglish: "this station is.. abandoned",
  //   gameType: gameType_Online,
  //   homepage: "abandoned",
  //   published: false,
  // },
  // {
  //   id: 6,
  //   quest_id: "JNSC02OFAI",
  //   firstLocation: false,
  //   gameLocation: gameLocation_seochon,
  //   gameLogoImg: gameLogoImg_Basecamp,
  //   textKorean: "이번 역은.. 기지편",
  //   textEnglish: "this station is.. basecamp",
  //   questPack: false,
  //   gameType: gameType_Offline,
  //   homepage: "basecamp",
  //   published: true,
  // },
];

function AllPages() {
  // const codes = [];
  // codes.map(item => {
  //     // console.log(item)
  //     UpLoadGames({ questCode: item })
  // })

  useEffect(() => {
    window.scrollTo(0, 0);
    sessionStorage.setItem("AB-PAGE", "PC");
    sessionStorage.setItem("AC-PAGE", "PC");
    sessionStorage.setItem("AD-PAGE", "PC");
    sessionStorage.setItem("AE-PAGE", "PC");
    // sessionStorage.setItem("AI-PAGE", "PC");

    sessionStorage.setItem("PLAY-NOW-CLICK", "NO");
  }, []);

  const navigate = useNavigate();

  const [whatPage, setWhatPage] = useState("");

  return (
    <React.Fragment>
      <Container>
        <GameLogoDiv>
          <GameLogoImg src={gameHiddenLogo} />
        </GameLogoDiv>
        <HomeDiv>
          {pagesData.map((item, index) => (
            <JustDiv key={index}>
              {item.firstLocation && (
                <JustDiv>
                  {whatPage === "" && (
                    <HomeImgDiv>
                      <GameLocationImg src={item.gameLocation} />
                    </HomeImgDiv>
                  )}

                  {whatPage === "eo" &&
                    item.gameLocation === gameLocation_eoeun && (
                      <JustDiv>
                        <HomeImgDiv>
                          {" "}
                          <GameLocationImg src={gameLocation_eoeun} />{" "}
                        </HomeImgDiv>
                        <HomeImgDiv>
                          {" "}
                          <GameLocationImg src={placeEo} />{" "}
                        </HomeImgDiv>
                        <GameMaterialsDiv>
                          {" "}
                          <GameMaterialsIcon src={placeIconEo} />{" "}
                        </GameMaterialsDiv>
                        <EoTxtKor />
                        <EoTxtEng />
                      </JustDiv>
                    )}

                  {whatPage === "mn" &&
                    item.gameLocation === gameLocation_mannyeon && (
                      <JustDiv>
                        <HomeImgDiv>
                          {" "}
                          <GameLocationImg src={gameLocation_mannyeon} />{" "}
                        </HomeImgDiv>
                        <HomeImgDiv>
                          {" "}
                          <GameLocationImg src={placeMn} />{" "}
                        </HomeImgDiv>
                        <GameMaterialsDiv>
                          {" "}
                          <GameMaterialsIcon src={placeIconMn} />{" "}
                        </GameMaterialsDiv>
                        <MnTxtKor />
                        <MnTxtEng />
                      </JustDiv>
                    )}
                </JustDiv>
              )}

              {whatPage === "" && item.firstLocation && (
                <HomeImgDiv>
                  <GameHomeImg
                    src={buttonVisitPlace}
                    onClick={() =>
                      item.gameLocation === gameLocation_eoeun
                        ? setWhatPage("eo")
                        : item.gameLocation === gameLocation_mannyeon
                        ? setWhatPage("mn")
                        : setWhatPage("")
                    }
                  />
                </HomeImgDiv>
              )}

              {whatPage === "" && (
                <JustDiv>
                  <GameHomeImgDiv>
                    <GameHomeImg
                      src={item.gameLogoImg}
                      onClick={() => navigate(`/${item.homepage}`)}
                    />
                  </GameHomeImgDiv>
                  <GameTitleContDiv>
                    <GameTitleDiv>
                      <GameTitleText>{item.textKorean}</GameTitleText>
                      <GameTitleText>
                        <GraySpan>{item.textEnglish}</GraySpan>
                      </GameTitleText>
                    </GameTitleDiv>
                    {item.questPack && (
                      <GameType>
                        <GameTypeImg src={gameQuestPack} />
                      </GameType>
                    )}
                    {!item.questPack && <GameType></GameType>}
                    <GameType>
                      <GameTypeImg src={item.gameType} />
                    </GameType>
                  </GameTitleContDiv>
                </JustDiv>
              )}

              {whatPage === "eo" &&
                item.gameLocation === gameLocation_eoeun &&
                item.published && (
                  <JustDiv>
                    <GameHomeImgDiv>
                      <GameHomeImg
                        src={item.gameLogoImg}
                        onClick={() => navigate(`/${item.homepage}`)}
                      />
                    </GameHomeImgDiv>
                    <GameTitleContDiv>
                      <GameTitleDiv>
                        <GameTitleText>{item.textKorean}</GameTitleText>
                        <GameTitleText>
                          <GraySpan>{item.textEnglish}</GraySpan>
                        </GameTitleText>
                      </GameTitleDiv>
                      {item.questPack && (
                        <GameType>
                          <GameTypeImg src={gameQuestPack} />
                        </GameType>
                      )}
                      {!item.questPack && <GameType></GameType>}
                      <GameType>
                        <GameTypeImg src={item.gameType} />
                      </GameType>
                    </GameTitleContDiv>
                  </JustDiv>
                )}

              {whatPage === "mn" &&
                item.gameLocation === gameLocation_mannyeon &&
                item.published && (
                  <JustDiv>
                    <GameHomeImgDiv>
                      <GameHomeImg
                        src={item.gameLogoImg}
                        onClick={() => navigate(`/${item.homepage}`)}
                      />
                    </GameHomeImgDiv>
                    <GameTitleContDiv>
                      <GameTitleDiv>
                        <GameTitleText>{item.textKorean}</GameTitleText>
                        <GameTitleText>
                          <GraySpan>{item.textEnglish}</GraySpan>
                        </GameTitleText>
                      </GameTitleDiv>
                      {item.questPack && (
                        <GameType>
                          <GameTypeImg src={gameQuestPack} />
                        </GameType>
                      )}
                      {!item.questPack && <GameType></GameType>}
                      <GameType>
                        <GameTypeImg src={item.gameType} />
                      </GameType>
                    </GameTitleContDiv>
                  </JustDiv>
                )}
            </JustDiv>
          ))}
          {whatPage === "" ? (
            <MiscellaneousDiv mt="50px">
              <Contact />
              <Footer />
            </MiscellaneousDiv>
          ) : (
            <>
              <BottomBtnHome>
                <BtnImg src={gameBtmBtnHome} onClick={() => setWhatPage("")} />
              </BottomBtnHome>
              <Padding25 />
            </>
          )}
        </HomeDiv>
      </Container>
    </React.Fragment>
  );
}

export default AllPages;
