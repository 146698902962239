import React, { useState } from 'react';

import PlatformStyles from '../../styles/PlatformStyles';

import { useNavigate } from 'react-router-dom';
import gameHiddenLogo from '../../assets/svg/gameHiddenLogo.svg';
import gameLocationImg from '../../assets/svg/gameLocation_eoeun.svg';
import gameLogoImg from '../../assets/svg/gameLogo_ryunge.svg';

import gameType from '../../assets/svg/Offline.svg';
import gameQuestPack from '../../assets/svg/questPack.svg';

import gameIconLevel from '../../assets/svg/levelDfkltyIcon.svg';
import gameIconPlyTm from '../../assets/svg/playTimeIcon.svg';
import gameIconType from '../../assets/svg/typeIcon.svg';
import gameIconStartPt from '../../assets/svg/startingPointIcon.svg';

import StarFull from '../../assets/svg/starFull.svg';
import StarHalf from '../../assets/svg/starHalf.svg';
import StarEmpty from '../../assets/svg/starEmpty.svg';

import gameCodeNextOFF from '../../assets/svg/nextButtonOFF.svg';

import gameCodeNextON from '../../assets/svg/nextButtonON.svg';

import playNowOFF from '../../assets/svg/playNowOFF.svg';

import gameBtmBtnLeft from '../../assets/svg/bottomButtonLeft.svg';
import gameBtmBtnHome from '../../assets/svg/bottomButtonHome.svg';
import gameBtmBtnRayt from '../../assets/svg/bottomButtonRight.svg';

const {
    Container,
    GameLogoDiv,
    GameLogoImg,
    HomeDiv,
    HomeImgDiv,
    GameLocationImg,
    GameHomeImg,
    GameTitleContDiv,
    GameTitleDiv,
    GameTitleText,
    GameType,
    GameTypeImg,
    GameInfoDiv,
    GameInfoIconDiv,
    GameInfoIcon,
    GameInfoTxtDiv,
    GameInfoTxtDiv23,
    GameInfoTxt,
    GameLvl,
    GameLvlStars,
    StartingPtTxt,
    EnterCodeTextDiv,
    GraySpan,
    BottomButtons,
    BottomBtnLeft,
    BottomBtnHome,
    BottomBtnRayt,
    BtnImg,
    BottomAnsDiv,
    InputField,
    NextBtn,
    InvalidCodeDiv,
    InvalidCodeText,
    BuyGameCodeTxt,
    BuyGameCodeImg,
    EnterGameCodeTxt,
    InputForm,
    TobeUpdatedDiv,
    ToBeUpdatedTxt,
    EmptyDiv
} = PlatformStyles;

function Ryunge() {
    const [answer, setAnswer] = useState('');
    const [validCode, setValidCode] = useState(true);
    const [input, setInput] = useState(false);
    const navigate = useNavigate();

    const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const enteredCode = event.target.value;
        setAnswer(enteredCode);
    };

    const nextBtnClick = () => {
        if (answer.length === 8) {
            setValidCode(true);
        }
        else {
            setValidCode(false);
        }
    };

    return (
        <>
            <Container>
                <GameLogoDiv>
                    <GameLogoImg src={gameHiddenLogo} />
                </GameLogoDiv>
                <HomeDiv>
                    <HomeImgDiv><GameLocationImg src={gameLocationImg} /></HomeImgDiv>

                    <HomeImgDiv><GameHomeImg src={gameLogoImg} /></HomeImgDiv>

                    <GameTitleContDiv>
                        <GameTitleDiv>
                            <GameTitleText>안녕마을 실종사건</GameTitleText>
                            <GameTitleText><GraySpan>Annyeong Town Mystery</GraySpan></GameTitleText>
                        </GameTitleDiv>
                        <GameType><GameTypeImg src={gameType} /></GameType>
                        <GameType><GameTypeImg src={gameQuestPack} /></GameType>
                    </GameTitleContDiv>

                    <GameInfoDiv>
                        <GameInfoIconDiv><GameInfoIcon src={gameIconLevel} /></GameInfoIconDiv>
                        <GameInfoTxtDiv>
                            <GameInfoTxt>난이도 <GraySpan>Level</GraySpan></GameInfoTxt>
                        </GameInfoTxtDiv>
                        <GameLvl>
                            <GameLvlStars src={StarFull} />
                            <GameLvlStars src={StarHalf} />
                            <GameLvlStars src={StarEmpty} />
                            <GameLvlStars src={StarEmpty} />
                            <GameLvlStars src={StarEmpty} />
                        </GameLvl>
                    </GameInfoDiv>
                    <GameInfoDiv>
                        <GameInfoIconDiv><GameInfoIcon src={gameIconPlyTm} /></GameInfoIconDiv>
                        <GameInfoTxtDiv>
                            <GameInfoTxt>소요시간 <GraySpan>Play Time</GraySpan></GameInfoTxt>
                        </GameInfoTxtDiv>
                        <GameLvl>
                            <GameInfoTxt>1~2시간 <GraySpan>hr</GraySpan></GameInfoTxt></GameLvl>
                    </GameInfoDiv>
                    <GameInfoDiv>
                        <GameInfoIconDiv><GameInfoIcon src={gameIconType} /></GameInfoIconDiv>
                        <GameInfoTxtDiv>
                            <GameInfoTxt>퀘스트 유형 <GraySpan>Type</GraySpan></GameInfoTxt>
                        </GameInfoTxtDiv>
                        <GameLvl>
                            <GameInfoTxt>야외 <GraySpan>Outdoor</GraySpan></GameInfoTxt>
                        </GameLvl>
                    </GameInfoDiv>
                    <GameInfoDiv>
                        <GameInfoIconDiv><GameInfoIcon src={gameIconStartPt} /></GameInfoIconDiv>
                        <GameInfoTxtDiv>
                            <GameInfoTxt>시작점<GraySpan>Starting Point</GraySpan></GameInfoTxt>
                        </GameInfoTxtDiv>
                        <GameLvl>
                            <GameInfoTxt></GameInfoTxt>
                        </GameLvl>
                    </GameInfoDiv>
                    <GameInfoDiv>
                        <GameInfoIconDiv></GameInfoIconDiv>
                        <GameInfoTxtDiv23>
                            <StartingPtTxt>어은동 안녕센터 108-11</StartingPtTxt>
                            <StartingPtTxt><GraySpan>Annyeong Center</GraySpan></StartingPtTxt>
                            <StartingPtTxt><GraySpan>Eoeundong 108-11</GraySpan></StartingPtTxt>
                        </GameInfoTxtDiv23>
                    </GameInfoDiv>

                    <TobeUpdatedDiv><BtnImg src={playNowOFF}/></TobeUpdatedDiv>

                    <TobeUpdatedDiv><ToBeUpdatedTxt /></TobeUpdatedDiv>

                    <EmptyDiv ht="111.44px" />

{/* 
                    <EnterCodeTextDiv><BuyGameCodeTxt /></EnterCodeTextDiv>

                    <BottomAnsDiv>
                        <a target="_blank" rel="noreferrer" href="https://smartstore.naver.com/fanveler/category/43eea0a0a0ce46a19014dc5685b43e5e?cp=1">
                            <BuyGameCodeImg />
                        </a>
                    </BottomAnsDiv>

                    <EnterCodeTextDiv><EnterGameCodeTxt /></EnterCodeTextDiv>

                    <BottomAnsDiv>
                        <InputField mw="210px">
                            <InputForm
                                type="text"
                                onChange={inputHandler}
                                placeholder=""
                                value={answer}
                                maxLength={10}
                                onClick={() => {
                                    setValidCode(true);
                                    setInput(true);
                                }}
                            />
                        </InputField>
                        {!input && <NextBtn src={gameCodeNextOFF} />}
                        {input && <NextBtn
                            src={gameCodeNextON}
                            onClick={() => nextBtnClick()}
                        />}
                    </BottomAnsDiv>

                    <InvalidCodeDiv>
                        {validCode && <InvalidCodeText>{`\xa0`}</InvalidCodeText>}
                        {!validCode && <InvalidCodeText>
                            잘못된 코드 <GraySpan>Invalid Code</GraySpan>
                        </InvalidCodeText>}
                    </InvalidCodeDiv> */}

                    <BottomButtons pb="15px">
                        <BottomBtnLeft>
                            <BtnImg
                                src={gameBtmBtnLeft}
                                onClick={() => navigate('/mnOff2')}
                            />
                        </BottomBtnLeft>
                        <BottomBtnHome>
                            <BtnImg
                                src={gameBtmBtnHome}
                                onClick={() => navigate('/allPages')}
                            />
                        </BottomBtnHome>
                        <BottomBtnRayt>
                            <BtnImg
                                src={gameBtmBtnRayt}
                                onClick={() => navigate('/ryungePrequal')}
                            />
                        </BottomBtnRayt>
                    </BottomButtons>
                </HomeDiv>
            </Container>
        </>
    );
};

export default Ryunge;