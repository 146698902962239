import styled from 'styled-components';

import Modal from 'react-overlays/Modal';

import buyNow from '../assets/svg/button_buynow.svg';

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #082631;
    min-height: 100vh;
    padding: 0 20px 60px 20px;
    overflow-x: hidden;
`;

const GameLogoDiv = styled.div`
    top: 44.58px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    // border-radius: 25px;
    height: 60px;
    z-index: 99;
`;

const GameLogoImg = styled.img`
    position: relative;
    max-width: 100%;
    height: auto;
`;

const HomeDiv = styled.div`
    top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 25px;
    background-color: #FFFFFF;
`;

const HomeImgDiv = styled.div<{mt?: string}>`
    width: 100%;
    display: flex;
    position: relative;
    align-contents: center;
    justify-content: center;
    margin-top: ${props => props.mt ? props.mt : "0px"}
`;

const GameLocationImg = styled.img`
    position: relative;
    max-width: 100%;
    height: auto;
    padding-top: 30px;
`;

const GameHomeImgDiv = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    align-contents: center;
    justify-content: center;
`;

const GameHomeImg = styled.img`
    position: relative;
    max-width: 100%;
    height: auto;
    padding-top: 20px;
`;

const GameTitleContDiv = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 15px 0 20px 0;
`;

const GameTitleDiv = styled.div`
    width: 200px;
`;

const GameTitleText = styled.h1`
    font-family: 'Noto700';
    font-size: 15px;
    line-height: 20px;
    align-items: center;
`;

const GameType = styled.div`
    width: 30px;
    height: 20px;
`;

const GameTypeImg = styled.img`
    max-width: 100%;
    height: auto;
`;

const GameInfoDiv = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
`;

const GameInfoIconDiv = styled.div`
    width: 20px;
    padding-right: 5px;
    padding-bottom: 10px;
    align-self: start;
`;

const GameInfoIcon = styled.img`
    width: 100%;
    height: auto;
    align-self: start;
`;

const GameInfoTxtDiv = styled.div`
    width: 165px;
    // padding-bottom: 10px;
    align-self: start;
`;

const GameInfoTxtDiv23 = styled.div`
    width: 255px;
    // padding-bottom: 10px;
    align-self: start;
`;

const GameInfoTxt = styled.h1`
    font-family: 'Noto400';
    font-weight: 500;
    font-size: 13px;
    color: #47444B;
`;

const GameLvl = styled.div`
    width: 90px;
    height: 15px;
    padding-bottom: 10px;
    align-self: start;
`;

const GameLvlStars = styled.img`
    max-width: 100%;
    height: auto;
`;

const StartingPtTxt = styled.h1`
    font-family: 'Noto400';
    font-size: 13px;
    line-height: 20px;
    color: #47444B;
`;

const EmptyDiv = styled.div<{ ht: string }>`
    width: 100%;
    min-height: ${(props) => props.ht};
`;

const EnterCodeTextDiv = styled.div`
    position: relative;
    display: flex;
    min-height: 15px;
    margin-top: 10px;
`;

const TobeUpdatedDiv = styled.div`
    position: relative;
    display: flex;
    min-height: 15px;
    margin-top: 20px;
`;

const EnterCodeText = styled.h1`
    font-family: 'Noto700';
    font-size: 15px;
    line-height: 15px;
    text-align: start;
    color: #47444B;
`;

const GraySpan = styled.span`
    color: #A0AEC0;  
`;

const BottomButtons = styled.div<{ pb: string }>`
    width: 300px;
    display: flex;
    flex-direction: row;
    // align-items: center;
    justify-content: space-between;
    position: relative;
    padding-bottom: ${props => props.pb};
    margin: 5px 17.5px 0 17.5px;
    // height: 60px;
`;

const BottomBtnLeft = styled.div`
    justify-content: flex-start;
    // height: 40px;
`;

const BottomBtnHome = styled.div`
    justify-content: center;
`;

const BottomBtnRayt = styled.div`
    justify-content: flex-end;
`;

const BtnImg = styled.img`
width: 100%;
    height: 40px;
`;

const BottomAnsDiv = styled.div`
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  padding-top: 10px;
`;

const BottomAnsDiv2 = styled.div<{pr: string}>`
  position: relative;
  width: 125px;
  margin-right: ${props => props.pr};
`;

const InputField = styled.div<{mw: string}>`
  position: relative;
//   padding-left: 10px;
  max-width: ${props => props.mw};;
`;

const NextBtn = styled.img`
    position: relative;
    margin-left: 10px;
    width: 40px;
    height: auto;
`;

const InvalidCodeDiv = styled.div`
    position: relative;
    align-items: center;
    flex-direction: row;
    padding: 20px 0 5px 0;
`;

const InvalidCodeText = styled.h1`
    font-family: 'Noto700';
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    color: #47444B;
`;

const MiscellaneousDiv = styled.div<{mt?: string}>`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: ${props => props.mt ? props.mt: "0px"};
    margin-bottom: 25px; 
`;

const TermsAndPrivacyModal = styled(Modal)`
  position: fixed;
  z-index: 1040;
//   top: 70px;
  margin: 0 auto;
  left: 0;
  right: 0;
  outline: none;
  lex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 375px;
  height: 520px;
  border-radius: 20px;
  background: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
`;

const GameMaterialsDiv = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 20px;
    padding-bottom: 20px;
`;

const GameMaterialsIcon = styled.img`
    max-width: 100%;
    height: auto;
`;

const GameIntroDiv = styled.div`
    width: 100%;
    display: grid;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 10px;
    white-space: pre-line;
`;

const GameIntroTxt = styled.h1`
    font-family: 'Noto400';
    font-size: 14px;
    line-height: 20px;
    color: #4A5568;
    text-align: center;
`;

const JustDiv = styled.div`
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Padding25 = styled.div`
  height: 25px;
`;

const NotMatchingDiv = styled.div`
    width: 100%;
    height: 35px;
    display: grid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0 10px 0; 
`;

const NotMatchingTxtDiv = styled.div`
    width: 100%;
    margin-bottom: 5px;
`;

const NotMatchingTxt = styled.h1<{clr: string}>`
    font-family: Noto700;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    color: ${props => props.clr};
`;

const BtnDiv = styled.div`
    width: 130px;
    height: 15px;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
`;

const BtnTxt = styled.h1`
    font-family: Noto400;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #1F7BE6;
`;

const InputDiv = styled.div<{mt?: string}>`  
  position: relative;
  max-width: 260px;
  margin-top: ${props => props.mt ? props.mt : "0px"}
`;

const InputFormSx = styled.input<{clk?: boolean}>`
    width: 100%;
    min-width: 50px;
    height: 40px;
    padding: 5px 20px 5px 20px;
    box-sizing: border-box;
    background: ${(props) => props.clk ? 'linear-gradient(#fff, #fff) padding-box, linear-gradient(0deg, #28BEC6, #1743FF) border-box' : '#F7FAFC'};
    border: ${(props) => props.clk ? '1px solid transparent' : '1px solid #64616C'};
    border-radius: 10px;
    outline: none;

    font-family: 'Noto400';
    font-size: 18px;
`;

const InputForm = styled.input`
    width: 100%;
    min-width: 50px;
    height: 40px;
    padding: 5px 20px 5px 20px;
    box-sizing: border-box;
    background: #F7FAFC;
    border: 1px solid #64616C;
    border-radius: 10px;
    outline: none;

    font-family: 'Noto400';
    font-size: 18px;

  &:focus {
    background: linear-gradient(#fff, #fff) padding-box,
                linear-gradient(0deg, #28BEC6, #1743FF) border-box;
    border: 1px solid transparent;
  }
  &::placeholder {
    font-family: Noto400;
    font-size: 13px;
    line-height: 15px;
    color: #47444B;
  }
  &::-webkit-input-placeholder {
    font-family: Noto400;
    font-size: 13px;
    line-height: 15px;
    color: #47444B;
  }
  &::-ms-input-placeholder {
    font-family: Noto400;
    font-size: 13px;
    line-height: 15px;
    color: #47444B;
  }
  &:-moz-placeholder {
    font-family: Noto400;
    font-size: 13px;
    line-height: 15px;
    color: #47444B;
  }
  &::-moz-placeholder {
    font-family: Noto400;
    font-size: 13px;
    line-height: 15px;
    color: #47444B;
  }
`;

const EoTxtEng = () => {
    return (
        <GameIntroDiv>
            <GameIntroTxt>
                {
                    `Mysterious Stories about Eoeun.
                    Save Eoeun in the past and present.
                    You are the only one 
                    who can solve this.`
                }
            </GameIntroTxt>
        </GameIntroDiv>
    )
};

const EoTxtKor = () => {
    return (
        <GameIntroDiv>
            <GameIntroTxt>
                {
                    `어은에 얽힌 신비한 이야기.
                    퀘스트를 완료해서 과거와 현재의 어은을
                    지켜주세요.
                    오직 당신 만이 해결할 수 있는 문제입니다.`
                }
                
            </GameIntroTxt>
        </GameIntroDiv>
    )
};

const MnTxtEng = () => {
    return (
        <GameIntroDiv>
            <GameIntroTxt>
                {
                    `Become our hero
                    and solve various cases
                    in the lush green Mannyeon.
                    We will be waiting for you
                    in a watery place.
                    Please open the dungeon door.`
                }
                
            </GameIntroTxt>
        </GameIntroDiv>
    )
};



const MnTxtKor = () => {
    return (
        <GameIntroDiv>
            <GameIntroTxt>
                {
                    `용사의 자격을 얻어
                    푸른 수목이 우거진 만년에서
                    일어나는 다양한 사건을 해결해주세요.
                    당신의 능력이 필요합니다.
                    물이 많은 곳에서 당신을 기다리겠습니다.
                    던전의 문을 열어주세요.`
                }
            </GameIntroTxt>
        </GameIntroDiv>
    )
};

const BuyGameCodeTxt = () => {
    return (
        <EnterCodeText>
            게임코드 구매하기 <GraySpan> Buy Game Code</GraySpan>
        </EnterCodeText>
    );
};

const BuyGameCodeImg = () => {
    return (<GameLvlStars src={buyNow} />);
}

const EnterGameCodeTxt = () => {
    return (
        <EnterCodeText>
            게임코드 입력하기 <GraySpan> Enter Game Code</GraySpan>
        </EnterCodeText>
    );
}

const ToBeUpdatedTxt = () => {
    return (
        <EnterCodeText>
            업데이트 예정 <GraySpan> To be updated</GraySpan>
        </EnterCodeText>
        
    );
};

export default {
    TermsAndPrivacyModal,
    Container,
    GameLogoDiv,
    GameLogoImg,
    HomeDiv,
    HomeImgDiv,
    GameLocationImg,
    GameHomeImgDiv,
    GameHomeImg,
    GameTitleContDiv,
    GameTitleDiv,
    GameTitleText,
    GameType,
    GameTypeImg,
    GameInfoDiv,
    GameInfoIconDiv,
    GameInfoIcon,
    GameInfoTxtDiv,
    GameInfoTxtDiv23,
    GameInfoTxt,
    GameLvl,
    GameLvlStars,
    StartingPtTxt,
    EmptyDiv,
    EnterCodeTextDiv,
    TobeUpdatedDiv,
    EnterCodeText,
    GraySpan,
    BottomButtons,
    BottomBtnLeft,
    BottomBtnHome,
    BottomBtnRayt,
    BtnImg,
    BottomAnsDiv,
    InputField,
    NextBtn,
    InvalidCodeDiv,
    InvalidCodeText,
    MiscellaneousDiv,
    GameMaterialsDiv,
    GameMaterialsIcon,
    BuyGameCodeTxt,
    BuyGameCodeImg,
    EnterGameCodeTxt,
    ToBeUpdatedTxt,
    EoTxtKor,
    EoTxtEng, 
    MnTxtKor, 
    MnTxtEng,
    JustDiv,
    Padding25,
    NotMatchingDiv,
    NotMatchingTxtDiv, 
    NotMatchingTxt,
    BtnDiv,
    BtnTxt,
    BottomAnsDiv2,
    InputDiv,
    InputForm,
    InputFormSx
}