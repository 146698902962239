import * as styled from 'styled-components';
import { reset } from 'styled-reset';

export default styled.createGlobalStyle`
  ${reset}
  *, *::before, *::after {
    box-sizing: border-box;
  }
  html {
    font-size: 1vw;
  }
  body {
    font-family: Noto400; // 폰트 설정
    background-color: 'purple';
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  ul {
    list-style: none;
  }
`;