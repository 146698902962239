import React, { useState, useEffect } from "react";

import Contact from "./Misc/Contact";

import PlatformStyles from "../styles/PlatformStyles";

import { useNavigate } from "react-router-dom";
import gameHiddenLogo from "../assets/svg/gameHiddenLogo.svg";
import gameSignupLogo from "../assets/svg/gameSignupLogo.svg";

import btnLoginGray from "../assets/svg/button_login_gray.svg";
import btnLoginBlue from "../assets/svg/button_login_blue.svg";
import btnLoading from "../assets/svg/loading_260.svg";

import gameBtmBtnHome from "../assets/svg/bottomButtonHome.svg";

import { getCookie, setCookie } from "../utils/cookies";
import { useMutation, useQuery } from "react-query";
import userApi from "../services/userApi";

const crypto = require("crypto-js");

const {
  Container,
  GameLogoDiv,
  GameLogoImg,
  HomeDiv,
  HomeImgDiv,
  GameLocationImg,
  BottomBtnHome,
  BtnImg,
  MiscellaneousDiv,
  BottomAnsDiv,
  Padding25,
  NotMatchingDiv,
  NotMatchingTxtDiv,
  NotMatchingTxt,
  BtnDiv,
  BtnTxt,
  BottomAnsDiv2,
  InputDiv,
  InputForm,
  InputFormSx,
} = PlatformStyles;

function Signup() {
  const gameData = [
    {
      qId: "YUEO02ONAB",
      navTo: "ryungePrequal",
    },
    {
      qId: "SUMN01ONAC",
      navTo: "mnOnline",
    },
    {
      qId: "SUMN02OFAD",
      navTo: "mnOff1",
    },
    {
      qId: "SUMN03OFAE",
      navTo: "mnOff2",
    },
    {
      qId: "JNSC02OFAI",
      navTo: "basecamp",
    },
  ];

  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [email, setEmail] = useState("");
  const [emailConfirm, setEmailConfirm] = useState("");
  const [pwd, setPwd] = useState("");
  const [pwdConfirm, setPwdConfirm] = useState("");
  const [byr, setByr] = useState("");
  const [sex, setSex] = useState("");

  const [mlClk, setMlClk] = useState(false);
  const [fmlClk, setFmlClk] = useState(false);

  const [infoMatches, setInfoMatches] = useState(true);
  const [loading, setLoading] = useState(false);

  const [isInfoInput, setIsInfoInput] = useState(false);

  const [errMsgKor, setErrMsgKor] = useState("");

  const [errMsgEng, setErrMsgEng] = useState("");

  const navigate = useNavigate();

  const inputName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredCode = event.target.value;
    setName(enteredCode);
  };

  const inputId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredCode = event.target.value;
    setId(enteredCode);
  };

  const inputEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredCode = event.target.value;
    setEmail(enteredCode);
  };

  const inputEmailConfirm = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredCode = event.target.value;
    setEmailConfirm(enteredCode);
  };

  const inputPwd = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredCode = event.target.value;
    setPwd(enteredCode);
  };

  const inputPwdConfirm = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredCode = event.target.value;
    setPwdConfirm(enteredCode);
  };

  const inputByr = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredCode = event.target.value;
    setByr(enteredCode);
  };

  const { refetch: checkUserAndSignup } = useQuery(
    "query-userDB-by-user_id",
    async () => {
      return await userApi.getUserById(id.toLowerCase());
    },
    {
      enabled: false,
      retry: 1,
      onSuccess: (res) => {
        if (res.Item?.user_id) {
          setErrMsgKor("이미사용된 아이디입니다.");
          setErrMsgEng("ID already in use");
          setInfoMatches(false);
          setLoading(false);
        } else if (id.length < 6) {
          setErrMsgKor("아이디: 6자 이상 영문/숫자 입력");
          setErrMsgEng("ID: Enter 6 or more characters");
          setInfoMatches(false);
          setLoading(false);
        } else if (!email.includes("@")) {
          setErrMsgKor("잘못된 이메일 형식입니다.");
          setErrMsgEng("Invalid Email Format");
          setInfoMatches(false);
          setLoading(false);
        } else if (email.toLowerCase() !== emailConfirm.toLowerCase()) {
          setErrMsgKor("이메일이 불일치합니다.");
          setErrMsgEng("Email does not match");
          setInfoMatches(false);
          setLoading(false);
        } else if (pwd.length < 8 || pwdConfirm.length < 8) {
          setErrMsgKor("비밀번호: 8자 이상 입력");
          setErrMsgEng("PW: Enter 8 or more characters");
          setInfoMatches(false);
          setLoading(false);
        } else if (pwd.toLowerCase() !== pwdConfirm.toLowerCase()) {
          setErrMsgKor("비밀번호가 불일치합니다.");
          setErrMsgEng("Password does not match");
          setInfoMatches(false);
          setLoading(false);
        } else {
          checkIfEmailAlreadyTaken();
        }
      },
      onError: (err: any) => {
        console.log(err.response?.data || err);
        navigate("/");
      },
    }
  );

  const { refetch: checkIfEmailAlreadyTaken } = useQuery(
    "query-userDB-by-email",
    async () => {
      return await userApi.checkUserExists(email.toLowerCase());
    },
    {
      enabled: false,
      retry: 1,
      onSuccess: (res) => {
        if (res.Items[0] && res.Items[0].user_id) {
          setErrMsgKor("이미 사용된 이메일입니다.");
          setErrMsgEng("Email already in use");
          setInfoMatches(false);
          setLoading(false);
        } else {
          setInfoMatches(true);
          SignupNewUser();
        }
      },
      onError: (err: any) => {
        console.log(err.response?.data || err);
        navigate("/");
      },
    }
  );

  const { mutate: SignupNewUser } = useMutation(
    async () => {
      const user_id = id.toLowerCase();
      return await userApi.signupUser(user_id, {
        userName: name.toLowerCase(),
        email: email.toLowerCase(),
        password: pwd.toLowerCase(),
        birth_yr: byr,
        sex: sex,
      });
    },
    {
      onSuccess: (res) => {
        const encode = crypto.AES.encrypt(id.toLowerCase(), "FANVELER");

        setCookie("USER_ID", `${encode}`, {
          domain: ".hiddenplace.io",
          path: "/",
          secure: true,
        });

        sessionStorage.setItem("LOGGED-IN", "YES");

        const getWhatP = sessionStorage.getItem("PLAY-NOW-CLICK");
        if (getWhatP === "YES") {
          gameData.map((item) => {
            if (item.qId === getCookie("QUEST_ID")) {
              sessionStorage.setItem(`${item.qId.slice(-2)}-PAGE`, "NCF");
              navigate(`/${item.navTo}`);
            }
          });
        } else {
          navigate("/allPages");
        }
      },
      onError: (err: any) => {
        console.log(err.response?.data || err);
        navigate("/");
      },
    }
  );

  useEffect(() => {
    if (
      name.length > 0 &&
      id.length > 0 &&
      email.length > 0 &&
      emailConfirm.length > 0 &&
      pwd.length > 0 &&
      pwdConfirm.length > 0 &&
      byr.length > 0 &&
      sex !== ""
    )
      setIsInfoInput(true);
    else setIsInfoInput(false);
  }, [name, id, email, emailConfirm, pwd, pwdConfirm, byr, sex]);

  return (
    <>
      <Container>
        <GameLogoDiv>
          <GameLogoImg src={gameHiddenLogo} />
        </GameLogoDiv>
        <HomeDiv>
          <HomeImgDiv>
            <GameLocationImg src={gameSignupLogo} />
          </HomeImgDiv>
          <Padding25 />
          <BottomAnsDiv>
            <InputDiv>
              <InputForm
                type="text"
                onChange={inputName}
                placeholder="이름 Name"
                value={name}
                maxLength={20}
                onClick={() => {
                  setInfoMatches(true);
                }}
              />
            </InputDiv>
          </BottomAnsDiv>
          <BottomAnsDiv>
            <InputDiv>
              <InputForm
                type="text"
                onChange={inputId}
                placeholder="아이디 ID"
                value={id}
                maxLength={20}
                onClick={() => {
                  setInfoMatches(true);
                }}
              />
            </InputDiv>
          </BottomAnsDiv>
          <BottomAnsDiv>
            <InputDiv>
              <InputForm
                type="text"
                onChange={inputEmail}
                placeholder="이메일 E-mail Address"
                value={email}
                onClick={() => {
                  setInfoMatches(true);
                }}
              />
            </InputDiv>
          </BottomAnsDiv>
          <BottomAnsDiv>
            <InputDiv>
              <InputForm
                type="text"
                onChange={inputEmailConfirm}
                placeholder="이메일 확인 E-mail Confirm"
                value={emailConfirm}
                onClick={() => {
                  setInfoMatches(true);
                }}
              />
            </InputDiv>
          </BottomAnsDiv>
          <BottomAnsDiv>
            <InputDiv>
              <InputForm
                type="password"
                onChange={inputPwd}
                placeholder="비밀번호 Password"
                value={pwd}
                maxLength={20}
                onClick={() => {
                  setInfoMatches(true);
                }}
              />
            </InputDiv>
          </BottomAnsDiv>
          <BottomAnsDiv>
            <InputDiv>
              <InputForm
                type="password"
                onChange={inputPwdConfirm}
                placeholder="비밀번호 확인 Password Confirm"
                value={pwdConfirm}
                maxLength={20}
                onClick={() => {
                  setInfoMatches(true);
                }}
              />
            </InputDiv>
          </BottomAnsDiv>
          <BottomAnsDiv>
            <InputDiv>
              <InputForm
                type="number"
                onChange={inputByr}
                placeholder="출생 연도 Birth Year"
                value={byr}
                maxLength={20}
                onClick={() => {
                  setInfoMatches(true);
                }}
              />
            </InputDiv>
          </BottomAnsDiv>
          <BottomAnsDiv>
            <BottomAnsDiv2 pr="10px">
              <InputFormSx
                clk={mlClk}
                type="button"
                value="Male"
                onClick={() => {
                  setMlClk(true);
                  setFmlClk(false);
                  setSex("Male");
                }}
              />
            </BottomAnsDiv2>
            <BottomAnsDiv2 pr="0px">
              <InputFormSx
                clk={fmlClk}
                type="button"
                value="Female"
                onClick={() => {
                  setFmlClk(true);
                  setMlClk(false);
                  setSex("Female");
                }}
              />
            </BottomAnsDiv2>
          </BottomAnsDiv>

          {!isInfoInput && (
            <BottomAnsDiv>
              <BtnImg src={btnLoginGray} />
            </BottomAnsDiv>
          )}

          {isInfoInput && (
            <BottomAnsDiv>
              {!loading && (
                <BtnImg
                  src={btnLoginBlue}
                  onClick={() => {
                    setLoading(true);
                    checkUserAndSignup();
                  }}
                />
              )}
              {loading && <BtnImg src={btnLoading} />}
            </BottomAnsDiv>
          )}

          <NotMatchingDiv>
            {!infoMatches && (
              <NotMatchingTxtDiv>
                <NotMatchingTxt clr="#47444B">{errMsgKor}</NotMatchingTxt>
              </NotMatchingTxtDiv>
            )}
            {!infoMatches && (
              <NotMatchingTxtDiv>
                <NotMatchingTxt clr="#A0AEC0">{errMsgEng}</NotMatchingTxt>
              </NotMatchingTxtDiv>
            )}
          </NotMatchingDiv>

          <BtnDiv>
            <a rel="noreferrer" href="/login">
              <BtnTxt>로그인 Log In</BtnTxt>
            </a>
          </BtnDiv>
          <BtnDiv>
            <a rel="noreferrer" href="#" onClick={() => navigate(-1)}>
              <BtnTxt>뒤로가기 Back</BtnTxt>
            </a>
          </BtnDiv>
          <MiscellaneousDiv mt="20px">
            <Contact />
          </MiscellaneousDiv>
          <BottomBtnHome>
            <BtnImg
              src={gameBtmBtnHome}
              onClick={() => navigate("/allPages")}
            />
          </BottomBtnHome>
          <Padding25 />
        </HomeDiv>
      </Container>
    </>
  );
}

export default Signup;
