import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  borderRadius: '320px',

  colors: {
    darkPurple: '#5A30D3',
    purple: '#7D50FF',
    lightPurple: '#9F7EFF',
    darkBlue: '#2C5282',
    blue: '#3182CE',
    lightBlue: '#63B3ED',
    black: '#1A202C',
    darkGray: '#4A5568',
    gray: '#A0AEC0',
    lightGray1: '#F7FAFC',
    lightGray2: '#E2E8F0',
    white: '#FFF',
  },
};

export { theme };