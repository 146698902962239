import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import userApi from '../../services/userApi';
import Contact from '../Misc/Contact';
import PlatformStyles from '../../styles/PlatformStyles';

import gameHiddenLogo from '../../assets/svg/gameHiddenLogo.svg';
import findPwdIdImg from '../../assets/svg/findPwdId.svg';
import btnLoginGray from '../../assets/svg/button_login_gray.svg';
import btnLoginBlue from '../../assets/svg/button_login_blue.svg';
import btnLoading from '../../assets/svg/loading_260.svg';
import gameBtmBtnHome from '../../assets/svg/bottomButtonHome.svg';

const crypto = require('crypto-js');

const {
  Container,
  GameLogoDiv,
  GameLogoImg,
  HomeDiv,
  HomeImgDiv,
  GameLocationImg,
  BottomBtnHome,
  BtnImg,
  MiscellaneousDiv,
  BottomAnsDiv,
  Padding25,
  NotMatchingDiv,
  NotMatchingTxtDiv,
  NotMatchingTxt,
  BtnDiv,
  BtnTxt,
  InputDiv,
  InputForm
} = PlatformStyles;

export default function FindUserID() {

  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [msgKor, setMsgKor] = useState('');
  const [msgEng, setMsgEng] = useState('');

  const [isEmailInput, setIsEmailInput] = useState(false);
  const [checkIfReturn, setCheckIfReturn] = useState(false);
  const [loading, setLoading] = useState(false);

  const inputEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredCode = event.target.value;
    setEmail(enteredCode);
  };

  useEffect(() => {
    if (email.length > 0) setIsEmailInput(true);
    else setIsEmailInput(false);
  }, [email]);

  const { refetch: checkIfUserExists } = useQuery(
    'query-userDB-by-user_id',
    async () => {
      return await userApi.checkUserExists(email.toLowerCase());
    },
    {
      enabled: false,
      retry: 1,
      onSuccess: (res) => {
        setCheckIfReturn(true);
        if (res.Items[0] && res.Items[0].user_id) {
      
          sendEmail(res.Items[0].user_id)
          // setUserID(res.Items[0].user_id);
          // navigate(`/changePassword/${crypto.SHA256(res.Items[0].user_id)}`)
          setMsgKor("이메일이 발송되었습니다.");
          setMsgEng("An email has been sent.");
        } else {
          setLoading(false);
          setMsgKor("계정을 찾을 수 없습니다.");
          setMsgEng("Account does not exist.");
        }
      },
      onError: (err: any) => {
        console.log(err.response?.data || err);
        navigate('/');
      }
    }
  );

  const { mutate: sendEmail } = useMutation(
    async (user_id: string) => {
      return await userApi.sendEmail(user_id, { email: email.toLowerCase() });
    },
    {
      onSuccess: (res) => {
        console.log("Email sent successfully");
      },
      onError: (err: any) => {
        console.log("Error sending email");
        console.log(err.response?.body || err);
        navigate('/');
      }
    }
  );

  return (
    <>
      <Container>
        <GameLogoDiv>
          <GameLogoImg src={gameHiddenLogo} />
        </GameLogoDiv>
        <HomeDiv>
          <HomeImgDiv><GameLocationImg src={findPwdIdImg} /></HomeImgDiv>
          <Padding25 />
          <BottomAnsDiv>
            <InputDiv>
              <InputForm
                type="text"
                onChange={inputEmail}
                placeholder="이메일 E-mail Address"
                value={email}
                onClick={() => {
                  setLoading(false);
                  setCheckIfReturn(false);
                }}
              />
            </InputDiv>
          </BottomAnsDiv>
          {!isEmailInput && <BottomAnsDiv>
            <BtnImg src={btnLoginGray} />
          </BottomAnsDiv>}

          {isEmailInput && <BottomAnsDiv>
            {!loading && <BtnImg
              src={btnLoginBlue}
              onClick={() => {
                setLoading(true);
                checkIfUserExists();
              }}
            />}
            {loading && <BtnImg src={btnLoading} />}

          </BottomAnsDiv>}

          <NotMatchingDiv>
            {checkIfReturn && <NotMatchingTxtDiv>
              <NotMatchingTxt clr="#47444B">{msgKor}</NotMatchingTxt>
            </NotMatchingTxtDiv>}
            {checkIfReturn && <NotMatchingTxtDiv>
              <NotMatchingTxt clr="#A0AEC0">{msgEng}</NotMatchingTxt>
            </NotMatchingTxtDiv>}
          </NotMatchingDiv>

          <Padding25 />
          <Padding25 />

          <BtnDiv>
            <a rel="noreferrer" href="/signup">
              <BtnTxt>회원가입 Sign Up</BtnTxt>
            </a>
          </BtnDiv>
          <BtnDiv>
            <a rel="noreferrer" href="/login">
              <BtnTxt>로그인  Log In</BtnTxt>
            </a>
          </BtnDiv>
          <BtnDiv>
            <a rel="noreferrer" href="#" onClick={() => navigate(-1)}>
              <BtnTxt>뒤로가기 Back</BtnTxt>
            </a>
          </BtnDiv>
          <MiscellaneousDiv mt="60px">
            <Contact />
          </MiscellaneousDiv>
          <BottomBtnHome>
            <BtnImg
              src={gameBtmBtnHome}
              onClick={() => navigate('/allPages')}
            />
          </BottomBtnHome>
          <Padding25 />
        </HomeDiv>
      </Container>
    </>
  );
};
