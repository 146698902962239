import React, { useState, useEffect } from 'react';

import Modal from 'react-overlays/Modal';

import PlatformStyles from '../../styles/PlatformStyles';

import lineVertical1 from '../../assets/svg/lineVertical.svg';
import lineVertical2 from '../../assets/svg/lineVertical2.svg'

import iconKakao from '../../assets/svg/iconKakao.svg';
import iconInsta from '../../assets/svg/iconInsta.svg';
import iconNaverBlog from '../../assets/svg/iconNaverBlog.svg';

import iconEmail from '../../assets/svg/iconMail.svg';
import iconPhone from '../../assets/svg/iconPhone.svg';


import termsUseKor from '../../assets/svg/termsKor.svg';
import termsUseEng from '../../assets/svg/termsEng.svg';
import prvcyPlcKor from '../../assets/svg/prvcyKor.svg';
import prvcyPlcEng from '../../assets/svg/prvcyEng.svg';


import closeICon from '../../assets/svg/closeIcon.svg';

import styled from 'styled-components';

import TermsOfUse from './TermsOfUse';
import PrivacyPolicy from './PrivacyPolicy';
import { useLocation, useNavigate } from 'react-router-dom';

const { TermsOfUseKor, TermsOfUseEng } = TermsOfUse;
const { PrivacyPolicyKor, PrivacyPolicyEng } = PrivacyPolicy;

const {
    HomeImgDiv,
    GameLocationImg,
    GameTitleText,
} = PlatformStyles;

const PageTermsAndPrivacyDiv = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: center;
    flex-direction: row;
    padding-bottom: 30px;
`;

const TermsOfUse_Kor = styled.div`
    width: 110px;
    height: 20px;
    margin-bottom: 20px;
`;

const TermsOfUse_Eng = styled.div`
    width: 110px;
    height: 20px;
    margin-bottom: 20px;
`;

const PrivacyPolicy_Kor = styled.div`
    width: 110px;
    height: 20px;
`;

const PrivacyPolicy_Eng = styled.div`
    width: 110px;
    height: 20px;
`;

const KoreanTxtDiv = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: end;
    justify-content: center;
    flex-direction: column;
    padding-right: 25px;
`;

const KoreanTxt = styled.h1`
    font-family: 'Noto400';
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
`;

const EnglishTxtDiv = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: start;
    flex-direction: column;
    padding-left: 25px;
`;

const EnglishTxt = styled.h1`
    font-family: 'Noto400';
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
`;

const Line = styled.img`
    max-width: 100%;
    height: auto;
`;

const SocialsDiv = styled.div`
    width: 100%;
    display: flex;
    padding: 4.5% 12.5% 8.5% 12.5%;
    // align-items: start;
    justify-content: space-evenly;
    flex-direction: row;
`;

const SocialDiv = styled.div`
`;

const PhoneDiv = styled.div`
    padding-right: 10px;
`;

const PhoneDivTxt = styled.h1`
    font-family: 'Noto400';
    font-size: 15px;
    height: 17px;
    color: #1A202C;
`;

const ContactUsDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const FooterLogoImg = styled.img`
    max-width: 100%;
    height: auto;
`;

const PhoneMailCont = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    // padding-bottom: 33px;
`;

const PhoneMailDiv = styled.div`
    display: flex;
    padding-bottom: 5px;
    align-items: center;
`;

const TermsAndPrivacyModal = styled(Modal)`
    position: fixed;
    z-index: 1040;
    top: 90px;
    margin: 0 auto;
    left: 0;
    right: 0;
    outline: none;
    flex-direction: column;
    align-items: center;
    max-width: 375px;
    height: 640px;
    border-radius: 25px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
`;

const HiInMaCont = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 25px;
  background: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
`;

const HiInMaNextBtn = styled.img`
    padding: 20px;
`;

const Backdrop = styled.div`
    position: fixed;
    z-index: 1040;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    opacity: 0.5;
`;

function Contact() {

    const [termsKor, setTermsKor] = useState(false);
    const [termsEng, setTermsEng] = useState(false);
    const [prvcyKor, setPrvcyKor] = useState(false);
    const [prvcyEng, setPrvcyEng] = useState(false);

    const ss = sessionStorage.getItem("LOGGED-IN");
    if (ss !== null) {
        sessionStorage.setItem("LOGGED-IN", ss);
    }
    else {
        sessionStorage.setItem("LOGGED-IN", "NO");
    }

    const loginState = sessionStorage.getItem("LOGGED-IN");

    const { pathname } = useLocation();

    const renderBackdrop = (props: JSX.IntrinsicAttributes) => (
        <Backdrop {...props} />
    );

    return (
        <>
            <PageTermsAndPrivacyDiv>
                <KoreanTxtDiv>
                    {pathname === '/allPages' && <TermsOfUse_Kor >
                        {loginState === "YES" &&
                            <a rel="noreferrer" href="/" onClick={() => {
                                sessionStorage.setItem("LOGGED-IN", "NO");
                                sessionStorage.setItem("PLAY-NOW-CLICK", "NO");
                                // navigate('/');
                            }}>
                                <KoreanTxt>Log Out</KoreanTxt>
                            </a>}
                        {loginState === "NO" &&
                            <a rel="noreferrer" href="login" 
                            // onClick={() => {navigate('/login');}}
                            >
                                <KoreanTxt>Login</KoreanTxt>
                            </a>}
                    </TermsOfUse_Kor>
                    }

                    <TermsOfUse_Kor
                        onClick={() => setTermsKor(!termsKor)}
                    >
                        <a rel="noreferrer" href="#">
                            <KoreanTxt>이용약관</KoreanTxt>
                        </a>
                    </TermsOfUse_Kor>
                    <PrivacyPolicy_Kor
                        onClick={() => setPrvcyKor(!prvcyKor)}
                    >
                        <a rel="noreferrer" href="#">
                            <KoreanTxt>개인정보취급방침</KoreanTxt>
                        </a>
                    </PrivacyPolicy_Kor>
                </KoreanTxtDiv>
                {pathname === '/allPages' 
                    ? <Line src={lineVertical2} />
                    : <Line src={lineVertical1} />}

                <EnglishTxtDiv>
                    <TermsOfUse_Eng
                        onClick={() => setTermsEng(!termsEng)}
                    >
                        <a rel="noreferrer" href="#">
                            <EnglishTxt>Terms of Use</EnglishTxt>
                        </a>
                    </TermsOfUse_Eng>
                    <PrivacyPolicy_Eng
                        onClick={() => setPrvcyEng(!prvcyEng)}
                    >
                        <a rel="noreferrer" href="#">
                            <EnglishTxt>Privacy Policy</EnglishTxt>
                        </a>
                    </PrivacyPolicy_Eng>
                </EnglishTxtDiv>
            </PageTermsAndPrivacyDiv>
            <ContactUsDiv><GameTitleText>Contact Us</GameTitleText></ContactUsDiv>
            <SocialsDiv>
                <SocialDiv>
                    <a target="_blank" rel="noreferrer" href="https://pf.kakao.com/_wxdxgWb">
                        <FooterLogoImg src={iconKakao} />
                    </a>
                </SocialDiv>
                <SocialDiv>
                    <a target="_blank" rel="noreferrer" href="https://www.instagram.com/hiddenplace_korea/">
                        <FooterLogoImg src={iconInsta} />
                    </a>
                </SocialDiv>
                <SocialDiv>
                    <a target="_blank" rel="noreferrer" href="https://blog.naver.com/fanveler">
                        <FooterLogoImg src={iconNaverBlog} />
                    </a>
                </SocialDiv>
            </SocialsDiv>

            <PhoneMailCont>
                <PhoneMailDiv>
                    <PhoneDiv><FooterLogoImg src={iconPhone} /></PhoneDiv>
                    <PhoneDivTxt>+82-2-6203-7227</PhoneDivTxt>
                </PhoneMailDiv>
                <PhoneMailDiv>
                    <PhoneDiv><FooterLogoImg src={iconEmail} /></PhoneDiv>
                    <PhoneDivTxt>support@fanveler.com</PhoneDivTxt>
                </PhoneMailDiv>
            </PhoneMailCont>

            <TermsAndPrivacyModal
                show={termsKor}
                onHide={() => setTermsKor(!termsKor)}
                renderBackdrop={renderBackdrop}
                aria-labelledby="modal-label"
            >
                <HiInMaCont>
                    <HomeImgDiv>
                        <GameLocationImg src={termsUseKor} />
                    </HomeImgDiv>
                    <TermsOfUseKor />
                    <HiInMaNextBtn
                        id="modal-label"
                        src={closeICon}
                        onClick={() => setTermsKor(!termsKor)}
                    />
                </HiInMaCont>
            </TermsAndPrivacyModal>
            <TermsAndPrivacyModal
                show={prvcyKor}
                onHide={() => setPrvcyKor(!prvcyKor)}
                renderBackdrop={renderBackdrop}
                aria-labelledby="modal-label"
            >
                <HiInMaCont>
                    <HomeImgDiv>
                        <GameLocationImg src={prvcyPlcKor} />
                    </HomeImgDiv>
                    <PrivacyPolicyKor />
                    <HiInMaNextBtn
                        id="modal-label"
                        src={closeICon}
                        onClick={() => setPrvcyKor(!prvcyKor)}
                    />
                </HiInMaCont>
            </TermsAndPrivacyModal>
            <TermsAndPrivacyModal
                show={termsEng}
                onHide={() => setTermsEng(!termsEng)}
                renderBackdrop={renderBackdrop}
                aria-labelledby="modal-label"
            >
                <HiInMaCont>
                    <HomeImgDiv>
                        <GameLocationImg src={termsUseEng} />
                    </HomeImgDiv>
                    <TermsOfUseEng />
                    <HiInMaNextBtn
                        id="modal-label"
                        src={closeICon}
                        onClick={() => setTermsEng(!termsEng)}
                    />
                </HiInMaCont>
            </TermsAndPrivacyModal>
            <TermsAndPrivacyModal
                show={prvcyEng}
                onHide={() => setPrvcyEng(!prvcyEng)}
                renderBackdrop={renderBackdrop}
                aria-labelledby="modal-label"
            >
                <HiInMaCont>
                    <HomeImgDiv>
                        <GameLocationImg src={prvcyPlcEng} />
                    </HomeImgDiv>
                    <PrivacyPolicyEng />
                    <HiInMaNextBtn
                        id="modal-label"
                        src={closeICon}
                        onClick={() => setPrvcyEng(!prvcyEng)}
                    />
                </HiInMaCont>
            </TermsAndPrivacyModal>
        </>


    )
}

export default Contact;