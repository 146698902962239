import axios from 'axios';

const userAPI = axios.create({
    baseURL: 'https://ey78b1x1rl.execute-api.ap-northeast-2.amazonaws.com',
    headers: {
        'Content-type': 'application/json'
    }
});

// ONLY for debugging purpose
// IGNORE IT!!
const deleteUserById = async (user_id: string) => {
    const response = await userAPI.delete<any>(`/users/${user_id}`);
    return response.data;
};

// gets all users and not really going to be used for current projects
// important for checking if the user exists in the userDB using 
// any attributes by iterating through all the users
// IGNORE IT!! (may be)
const getUserAll = async () => {
    const response = await userAPI.get('/users');
    return response.data;
};

// gets all user information by using the user_id
// Important for:
// checking if the user_id exists (?)
// checking if the user_id and password matches
// getting the quests_code
// and ettc...
const getUserById = async (user_id: string) => {
    const response = await userAPI.get(`/users/${user_id}`);
    return response.data;
};

// for Platform NOT Quests
// IGNORE IT!!
const signupUser = async (
    user_id: string,
    {
        userName,
        sex,
        birth_yr,
        email,
        password,
    }: any) => {
    const response = await userAPI.put<any>(`/users/${user_id}`, {
        userName,
        email,
        password,
        birth_yr,
        sex,
    });
    return response.data;
};

// for changing the user language preference
// only when the user changes the language setup 
// (maximum of one call per story: one call per story should be enough)
const updateUserLangPreference = async (
    user_id: string,
    {
        language,
    }: any) => {
    const response = await userAPI.put(`/language/${user_id}`, { language });
    return response.data;
};

// for updating the list of quests_code
// quests_code is an array of strings
const updateUserQuestsCode = async (
    user_id: string,
    {
        quests_code,
    }: any) => {
    const response = await userAPI.put(`/quest_code/${user_id}`, { quests_code });
    return response.data;
};

const checkUserExists = async (user_email: string) => {
    try {
        const response = await userAPI.get(`/email/${user_email}`);
        return response.data;
    } catch (error) {
        console.log('Error sending user Email:', error);
    }
};

const sendEmail = async (user_id: string, { email }: any) => {
    try {
        const response = await userAPI.post(`/email/${user_id}`, { email });
        return response.data;
    } catch (error) {
        console.log('Error sending user ID:', error);
    }
};

const changePassword = async (user_id: string, { password }: any) => {
    try {
        const response = await userAPI.put(`/password/${user_id}`, { password });
        return response.data;
    } catch (error) {
        console.log("Error Changing Password:", error)
    }
};

const userApi = {
    deleteUserById,
    getUserAll,
    getUserById,
    signupUser,
    updateUserLangPreference,
    updateUserQuestsCode,
    checkUserExists,
    sendEmail,
    changePassword
};

export default userApi;