import React, { useState, useEffect } from 'react';

import lineHorizontal from '../../assets/svg/lineHorizontal.svg'

import footerLogo from '../../assets/svg/footerFanvelerLogo.svg';

import styled from 'styled-components';

const FooterDiv = styled.div`
    width: 100%;
    display: grid;
    // align-items: center;
    justify-content: center;
    margin: 0 17.5px 0 17.5px;
`;

const Line = styled.img`
    max-width: 100%;
    justify-content: center;
    height: auto;
`;

const FooterHeaderTxt = styled.h1`
    font-family: 'Noto700';
    font-size: 16px;
    line-height: 20px;
    align-items: center;
`;

const FooterTxtDiv = styled.div`
    width: 100%;
    display: flex;
    padding-top: 20px;
    padding-bottom: 10px;
`;

const FooterLogoDiv = styled.div`
    width: 100%;
    display: flex;
    padding-bottom: 10px;
    padding-left: 10px;
`;

const FooterLogoImg = styled.img`
    max-width: 100%;
    height: auto;
`;

const FooterInfo = styled.div`
    width: 100%;
    display: flex; 
    align-items: start;
    flex-direction: column;
    padding-bottom: 15px;
    padding-right: 15px;
`;

const FooterInfoTxtDiv = styled.div`
    width: 100%;
    padding-bottom: 5px
`;

const FooterInfoTxt = styled.h1`
    font-family: Noto400;
    font-size: 13px;
    line-height: 15px;
    color: #64616C;
`;

function Footer() {

    return (
        <FooterDiv>
            <Line src={lineHorizontal} />
            <FooterTxtDiv>
                <FooterHeaderTxt>주식회사 팬블러 Fanveler Inc.</FooterHeaderTxt>
            </FooterTxtDiv>
            <FooterLogoDiv>
                <FooterLogoImg src={footerLogo} />
            </FooterLogoDiv>

            <FooterInfo>
                <FooterInfoTxtDiv>
                    <FooterInfoTxt>CEO 배현혜 Bae, Hyun Hye</FooterInfoTxt>
                </FooterInfoTxtDiv>
                <FooterInfoTxtDiv>
                    <FooterInfoTxt>대전광역시 서구 둔산대로 117번길 66, 12층</FooterInfoTxt>
                </FooterInfoTxtDiv>
                <FooterInfoTxtDiv>
                    <FooterInfoTxt>Daejeon, Republic of Korea</FooterInfoTxt>
                </FooterInfoTxtDiv>
                <FooterInfoTxtDiv>
                    <FooterInfoTxt>사업자등록번호 871-87-01979</FooterInfoTxt>
                </FooterInfoTxtDiv>
                <FooterInfoTxtDiv>
                    <FooterInfoTxt>통신판매업신고번호 2022-서구-0032</FooterInfoTxt>
                </FooterInfoTxtDiv>
            </FooterInfo>
        </FooterDiv>


    )
}

export default Footer;