import styled from 'styled-components';

const TxtDiv = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 35px 17% 25px 17%;
  white-space: pre-line;
  overflow-x: hidden;
  overflow-y: auto;
  word-break: break-word;
`;

const Txt = styled.h1`
   font-family: 'Noto400';
   font-size: 13px;
   line-height: 14px;
   color: #000000;
   text-align: justify;
`;

const TermsOfUseKor = () => {

        const TermsKor = `
                제1조 (목적)
                이 약관은 주식회사 팬블러(전자상거래 사업자 이하 “회사”라 한다)가 운영하는 팬블러 사이버 몰(이하 “팬블러”라 한다)에서 제공하는 인터넷 관련 서비스(이하 “서비스”라 한다)를 이용함에 있어 사이버 몰과 이용자의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.
                
                ※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.」 

                제2조 (정의)
                ①“팬블러”란 주식회사 팬블러가 재화 또는 용역(이하 “재화 등”이라 함)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버몰을 운영하는 사업자의 의미로도 사용합니다.
                ②“이용자”란 “팬블러”에 접속하여 이 약관에 따라 “팬블러”가 제공하는 서비스를 받는 회원 및 비회원을 말합니다. 
                ③‘회원’이라 함은 “팬블러”에 회원등록을 한 자로서, 계속적으로 “팬블러”가 제공하는 서비스를 이용할 수 있는 자를 말합니다.
                ④‘비회원’이라 함은 회원에 가입하지 않고 “팬블러”가 제공하는 서비스를 이용하는 자를 말합니다.
                
                제3조 (약관 등의 명시와 설명 및 개정) 
                ①“팬블러”는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호․모사전송번호․전자우편주소, 사업자등록번호, 통신판매업 신고번호, 개인정보관리책임자등을 이용자가 쉽게 알 수 있도록 팬블러 사이버몰의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.                
                ②“팬블러”는 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회·배송책임·환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다. 
                ③“팬블러”는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다. 
                ④“팬블러”가 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 몰의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 “팬블러”은 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다. 
                ⑤“팬블러”가 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 “팬블러”에 송신하여 “팬블러”의 동의를 받은 경우에는 개정약관 조항이 적용됩니다. 
                ⑥이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다. 

                제4조 (서비스의 제공 및 변경) 
                ①“팬블러”는 다음과 같은 업무를 수행합니다. 
                1.재화 또는 용역에 대한 정보 제공 및 구매계약의 체결 
                2.구매계약이 체결된 재화 또는 용역의 배송 
                3.기타 “팬블러”가 정하는 업무 
                ②“팬블러”는 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다. 
                ③“팬블러”가 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다. 
                ④전항의 경우 “팬블러”는 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, “팬블러”가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다. 

                제5조 (서비스의 중단) 
                ①“팬블러”는 컴퓨터 등 정보통신설비의 보수점검․교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다. 
                ②“팬블러”는 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, “팬블러”가 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다. 
                ③사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 “팬블러”는 제8조에 정한 방법으로 이용자에게 통지하고 당초 “팬블러”에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, “팬블러”가 보상기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 “팬블러”에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다. 

                제6조 (회원가입) 
                ①이용자는 “팬블러”가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다. 
                ②“팬블러”는 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다. 
                1.가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 “팬블러”의 회원재가입 승낙을 얻은 경우에는 예외로 한다. 
                2.등록 내용에 허위, 기재누락, 오기가 있는 경우 
                3.기타 회원으로 등록하는 것이 “팬블러”의 기술상 현저히 지장이 있다고 판단되는 경우 
                ③회원가입계약의 성립 시기는 “팬블러”의 승낙이 회원에게 도달한 시점으로 합니다. 
                ④회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 “팬블러”에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다. 

                제7조 (회원 탈퇴 및 자격 상실 등) 
                ①회원은 “팬블러”에 언제든지 탈퇴를 요청할 수 있으며 “팬블러”는 즉시 회원탈퇴를 처리합니다. 
                ②회원이 다음 각 호의 사유에 해당하는 경우, “팬블러”는 회원자격을 제한 및 정지시킬 수 있습니다. 
                1.가입 신청 시에 허위 내용을 등록한 경우 
                2.“팬블러”를 이용하여 구입한 재화 등의 대금, 기타 “팬블러”이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우 
                3.다른 사람의 “팬블러” 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우 
                4.“팬블러”를 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우 
                ③“팬블러”가 회원 자격을 제한․정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 “팬블러”는 회원자격을 상실시킬 수 있습니다. 
                ④“팬블러”가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다. 

                제8조 (회원에 대한 통지) 
                ①“팬블러”가 회원에 대한 통지를 하는 경우, 회원이 “팬블러”와 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다. 
                ②“팬블러”는 불특정다수 회원에 대한 통지의 경우 1주일이상 “팬블러” 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.

                제9조 (구매신청 및 개인정보 제공 동의 등) 
                ①“팬블러”이용자는 “팬블러”상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, “팬블러”는 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다. 
                1.재화 등의 검색 및 선택 
                2.받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력 
                3.약관내용, 청약철회권이 제한되는 서비스, 배송료․설치비 등의 비용부담과 관련한 내용에 대한 확인 
                4.이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는 표시 (예, 마우스 클릭) 
                5.재화등의 구매신청 및 이에 관한 확인 또는 “팬블러”의 확인에 대한 동의 
                6.결제방법의 선택 
                ②“팬블러”가 제3자에게 구매자 개인정보를 제공할 필요가 있는 경우 1) 개인정보를 제공받는 자, 2)개인정보를 제공받는 자의 개인정보 이용목적, 3) 제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.) 
                ③“팬블러”가 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1) 개인정보 취급위탁을 받는 자, 2) 개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.) 다만, 서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다. 

                제10조 (계약의 성립) 
                ①“팬블러”는 제9조와 같은 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다. 
                1.신청 내용에 허위, 기재누락, 오기가 있는 경우 
                2.미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우 
                3.기타 구매신청에 승낙하는 것이 “팬블러” 기술상 현저히 지장이 있다고 판단하는 경우 
                ②“팬블러”의 승낙이 제12조제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다. 
                ③“팬블러”의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다. 

                제11조 (지급방법) 
                “팬블러”에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 방법중 가용한 방법으로 할 수 있습니다. 단, “팬블러”는 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다. 
                1.폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체 
                2.선불카드, 직불카드, 신용카드 등의 각종 카드 결제 
                3.온라인무통장입금 
                4.전자화폐에 의한 결제 
                5.수령 시 대금지급 
                6.마일리지 등 “팬블러”이 지급한 포인트에 의한 결제 
                7.“팬블러”와 계약을 맺었거나 “팬블러”가 인정한 상품권에 의한 결제 
                8.기타 전자적 지급 방법에 의한 대금 지급 등

                제12조 (수신확인통지․구매신청 변경 및 취소) 
                ①“팬블러”는 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다. 
                ②수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고 “팬블러”는 배송 전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제15조의 청약철회 등에 관한 규정에 따릅니다.

                제13조 (재화 등의 공급) 
                ①“팬블러”는 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 날부터 7일 이내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다. 다만, “팬블러”가 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 3영업일 이내에 조치를 취합니다. 이때 “팬블러”는 이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다. 
                ②“팬블러”는 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다. 만약 “팬블러”이 약정 배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상하여야 합니다. 다만 “팬블러”가 고의․과실이 없음을 입증한 경우에는 그러하지 아니합니다. 

                제14조 (환급) 
                “팬블러”는 이용자가 구매신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다. 

                제15조 (청약철회 등) 
                ①“팬블러”와 재화등의 구매에 관한 계약을 체결한 이용자는 「전자상거래 등에서의 소비자보호에 관한 법률」 제13조 제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진 경우에는 재화 등을 공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터 7일 이내에는 청약의 철회를 할 수 있습니다. 다만, 청약철회에 관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에 달리 정함이 있는 경우에는 동 법 규정에 따릅니다. 
                ②이용자는 재화 등을 배송 받은 경우 다음 각 호의 1에 해당하는 경우에는 반품 및 교환을 할 수 없습니다. 
                1.이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는 청약철회를 할 수 있습니다) 
                2.이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우 
                3.시간의 경과에 의하여 재판매가 곤란할 정도로 재화등의 가치가 현저히 감소한 경우 
                4.같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우 
                ③제2항제2호 내지 제4호의 경우에 “팬블러”가 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이 제한되지 않습니다. 
                ④이용자는 제1항 및 제2항의 규정에 불구하고 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다. 

                제16조 (청약철회 등의 효과) 
                ①“팬블러”는 이용자로부터 재화 등을 반환받은 경우 3영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다. 이 경우 “팬블러”가 이용자에게 재화등의 환급을 지연한때에는 그 지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」제21조의2에서 정하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다. 
                ②“팬블러”는 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다. 
                ③청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. “팬블러”는 이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 “팬블러”가 부담합니다. 
                ④이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 “팬블러”는 청약철회 시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다. 

                제17조 (개인정보보호) 
                ①“팬블러”는 이용자의 개인정보 수집시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다. 
                ②“팬블러”는 회원가입시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다. 
                ③“팬블러”는 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 
                ④“팬블러”는 수집된 개인정보를 목적외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다. 
                ⑤“팬블러”가 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다. 
                ⑥이용자는 언제든지 “팬블러”가 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 “팬블러”는 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 “팬블러”는 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다. 
                ⑦“팬블러”는 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를 최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다. 
                ⑧“팬블러” 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다. 
                ⑨“팬블러”는 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한 이용자의 동의거절시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다. 

                제18조 (“팬블러”의 의무) 
                ①“팬블러”는 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화·용역을 제공하는데 최선을 다하여야 합니다. 
                ②“팬블러”는 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다. 
                ③“팬블러”가 상품이나 용역에 대하여 「표시·광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시·광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다. 
                ④“팬블러”는 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다. 

                제19조 (회원의 ID 및 비밀번호에 대한 의무) 
                ①제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다. 
                ②회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다. 
                ③회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 “팬블러”에 통보하고 “팬블러”의 안내가 있는 경우에는 그에 따라야 합니다. 

                제20조 (이용자의 의무) 
                이용자는 다음 행위를 하여서는 안 됩니다. 
                1.신청 또는 변경시 허위 내용의 등록 
                2.타인의 정보 도용 
                3.“팬블러”에 게시된 정보의 변경 
                4.“팬블러” 가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시 
                5.“팬블러” 기타 제3자의 저작권 등 지적재산권에 대한 침해 
                6.“팬블러” 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위 
                7.외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 몰에 공개 또는 게시하는 행위 

                제21조 (저작권의 귀속 및 이용제한) 
                ①“팬블러”가 작성한 저작물에 대한 저작권 기타 지적재산권은 “팬블러”에 귀속합니다. 
                ②이용자는 “팬블러”를 이용함으로써 얻은 정보 중 “팬블러”에게 지적재산권이 귀속된 정보를 “팬블러”의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다. 
                ③“팬블러”는 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다. 

                제22조 (분쟁해결) 
                ①“팬블러”는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치․운영합니다. 
                ②“팬블러”는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다. 
                ③“팬블러”와 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.

                제23조 (재판권 및 준거법) 
                ①“팬블러”와 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다. 
                ②“팬블러”와 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.
        `;

        return (
                <TxtDiv>
                        <Txt>{TermsKor}</Txt>
                </TxtDiv>
        );
}

const TermsOfUseEng = () => {
        const TermsEng = `
                OVERVIEW 
                This website is operated by Fanveler Inc. Throughout the site, the terms “we”, “us” and “our” refer to Fanveler Inc. Fanveler Inc. offers this website, including all information, tools and services available from this site to you, the user, conditioned upon your acceptance of all terms, conditions, policies and notices stated here. 
                
                By visiting our site and/ or purchasing something from us, you engage in our “Service” and agree to be bound by the following terms and conditions (“Terms of Service”, “Terms”), including those additional terms and conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all users of the site, including without limitation users who are browsers, vendors, customers, merchants, and/ or contributors of content. 
                
                Please read these Terms of Service carefully before accessing or using our website. By accessing or using any part of the site, you agree to be bound by these Terms of Service. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services. If these Terms of Service are considered an offer, acceptance is expressly limited to these Terms of Service. 
                
                Any new features or tools which are added to the current store shall also be subject to the Terms of Service. You can review the most current version of the Terms of Service at any time on this page. We reserve the right to update, change or replace any part of these Terms of Service by posting updates and/or changes to our website. It is your responsibility to check this page periodically for changes. Your continued use of or access to the website following the posting of any changes constitutes acceptance of those changes. 
                
                SECTION 1 - ONLINE STORE TERMS 
                By agreeing to these Terms of Service, you represent that you are at least the age of majority in your state or province of residence, or that you are the age of majority in your state or province of residence and you have given us your consent to allow any of your minor dependents to use this site. 
                
                You may not use our products for any illegal or unauthorized purpose nor may you, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws). 
                
                You must not transmit any worms or viruses or any code of a destructive nature. 
                
                A breach or violation of any of the Terms will result in an immediate termination of your Services. 
                
                SECTION 2 - GENERAL CONDITIONS 
                We reserve the right to refuse service to anyone for any reason at any time. 
                
                You understand that your content (not including credit card information), may be transferred unencrypted and involve (a) transmissions over various networks; and (b) changes to conform and adapt to technical requirements of connecting networks or devices. Credit card information is always encrypted during transfer over networks. 
                
                You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service or any contact on the website through which the service is provided, without express written permission by us. 
                
                The headings used in this agreement are included for convenience only and will not limit or otherwise affect these Terms. 
                
                SECTION 3 - ACCURACY, COMPLETENESS AND TIMELINESS OF INFORMATION 
                We are not responsible if information made available on this site is not accurate, complete or current. The material on this site is provided for general information only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the material on this site is at your own risk. 
                
                This site may contain certain historical information. Historical information, necessarily, is not current and is provided for your reference only. We reserve the right to modify the contents of this site at any time, but we have no obligation to update any information on our site. You agree that it is your responsibility to monitor changes to our site. 
                
                SECTION 4 - MODIFICATIONS TO THE SERVICE AND PRICES 
                Prices for our products are subject to change without notice. 
                
                We reserve the right at any time to modify or discontinue the Service (or any part or content thereof) without notice at any time. 
                
                We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the Service. 
                
                SECTION 5 - PRODUCTS OR SERVICES (if applicable) 
                Certain products or services may be available exclusively online through the website. These products or services may have limited quantities and are subject to return or exchange only according to our Return Policy. 
                
                We have made every effort to display as accurately as possible the colors and images of our products that appear at the store. We cannot guarantee that your computer monitor's display of any color will be accurate. 
                
                We reserve the right, but are not obligated, to limit the sales of our products or Services to any person, geographic region or jurisdiction. We may exercise this right on a case-by-case basis. We reserve the right to limit the quantities of any products or services that we offer. All descriptions of products or product pricing are subject to change at anytime without notice, at the sole discretion of us. We reserve the right to discontinue any product at any time. Any offer for any product or service made on this site is void where prohibited. 
                
                We do not warrant that the quality of any products, services, information, or other material purchased or obtained by you will meet your expectations, or that any errors in the Service will be corrected. 
                
                SECTION 6 - ACCURACY OF BILLING AND ACCOUNT INFORMATION 
                We reserve the right to refuse any order you place with us. We may, in our sole discretion, limit or cancel quantities purchased per person, per household or per order. These restrictions may include orders placed by or under the same customer account, the same credit card, and/or orders that use the same billing and/or shipping address. In the event that we make a change to or cancel an order, we may attempt to notify you by contacting the e-mail and/or billing address/phone number provided at the time the order was made. We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers or distributors. 
                
                You agree to provide current, complete and accurate purchase and account information for all purchases made at our store. You agree to promptly update your account and other information, including your email address and credit card numbers and expiration dates, so that we can complete your transactions and contact you as needed. 
                
                For more detail, please review our Returns Policy. 
                
                SECTION 7 - OPTIONAL TOOLS 
                We may provide you with access to third-party tools over which we neither monitor nor have any control nor input. 
                
                You acknowledge and agree that we provide access to such tools “as is” and “as available” without any warranties, representations or conditions of any kind and without any endorsement. We shall have no liability whatsoever arising from or relating to your use of optional third-party tools. 
                
                Any use by you of optional tools offered through the site is entirely at your own risk and discretion and you should ensure that you are familiar with and approve of the terms on which tools are provided by the relevant third-party provider(s). 
                
                We may also, in the future, offer new services and/or features through the website (including, the release of new tools and resources). Such new features and/or services shall also be subject to these Terms of Service. 
                
                SECTION 8 - THIRD-PARTY LINKS 
                Certain content, products and services available via our Service may include materials from third-parties. 
                
                Third-party links on this site may direct you to third-party websites that are not affiliated with us. We are not responsible for examining or evaluating the content or accuracy and we do not warrant and will not have any liability or responsibility for any third-party materials or websites, or for any other materials, products, or services of third-parties. 
                
                We are not liable for any harm or damages related to the purchase or use of goods, services, resources, content, or any other transactions made in connection with any third-party websites. Please review carefully the third-party's policies and practices and make sure you understand them before you engage in any transaction. Complaints, claims, concerns, or questions regarding third-party products should be directed to the third-party. 
                
                SECTION 9 - USER COMMENTS, FEEDBACK AND OTHER SUBMISSIONS 
                If, at our request, you send certain specific submissions (for example contest entries) or without a request from us you send creative ideas, suggestions, proposals, plans, or other materials, whether online, by email, by postal mail, or otherwise (collectively, 'comments'), you agree that we may, at any time, without restriction, edit, copy, publish, distribute, translate and otherwise use in any medium any comments that you forward to us. We are and shall be under no obligation (1) to maintain any comments in confidence; (2) to pay compensation for any comments; or (3) to respond to any comments. 
                
                We may, but have no obligation to, monitor, edit or remove content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene or otherwise objectionable or violates any party’s intellectual property or these Terms of Service. 
                
                You agree that your comments will not violate any right of any third-party, including copyright, trademark, privacy, personality or other personal or proprietary right. You further agree that your comments will not contain libelous or otherwise unlawful, abusive or obscene material, or contain any computer virus or other malware that could in any way affect the operation of the Service or any related website. You may not use a false e-mail address, pretend to be someone other than yourself, or otherwise mislead us or third-parties as to the origin of any comments. You are solely responsible for any comments you make and their accuracy. We take no responsibility and assume no liability for any comments posted by you or any third-party. 
                
                SECTION 10 - PERSONAL INFORMATION 
                Your submission of personal information through the store is governed by our Privacy Policy. To view our Privacy Policy. 
                
                SECTION 11 - ERRORS, INACCURACIES AND OMISSIONS 
                Occasionally there may be information on our site or in the Service that contains typographical errors, inaccuracies or omissions that may relate to product descriptions, pricing, promotions, offers, product shipping charges, transit times and availability. We reserve the right to correct any errors, inaccuracies or omissions, and to change or update information or cancel orders if any information in the Service or on any related website is inaccurate at any time without prior notice (including after you have submitted your order). 
                
                We undertake no obligation to update, amend or clarify information in the Service or on any related website, including without limitation, pricing information, except as required by law. No specified update or refresh date applied in the Service or on any related website, should be taken to indicate that all information in the Service or on any related website has been modified or updated. 
                
                SECTION 12 - PROHIBITED USES 
                In addition to other prohibitions as set forth in the Terms of Service, you are prohibited from using the site or its content: (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts; (c) to violate any international, federal, provincial or state regulations, rules, laws, or local ordinances; (d) to infringe upon or violate our intellectual property rights or the intellectual property rights of others; (e) to harass, abuse, insult, harm, defame, slander, disparage, intimidate, or discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or disability; (f) to submit false or misleading information; (g) to upload or transmit viruses or any other type of malicious code that will or may be used in any way that will affect the functionality or operation of the Service or of any related website, other websites, or the Internet; (h) to collect or track the personal information of others; (i) to spam, phish, pharm, pretext, spider, crawl, or scrape; (j) for any obscene or immoral purpose; or (k) to interfere with or circumvent the security features of the Service or any related website, other websites, or the Internet. We reserve the right to terminate your use of the Service or any related website for violating any of the prohibited uses. 
                
                SECTION 13 - DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY 
                We do not guarantee, represent or warrant that your use of our service will be uninterrupted, timely, secure or error-free. 
                
                We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable. 
                
                You agree that from time to time we may remove the service for indefinite periods of time or cancel the service at any time, without notice to you. 
                
                You expressly agree that your use of, or inability to use, the service is at your sole risk. The service and all products and services delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available' for your use, without any representation, warranties or conditions of any kind, either express or implied, including all implied warranties or conditions of merchantability, merchantable quality, fitness for a particular purpose, durability, title, and non-infringement. 
                
                In no case shall Fanveler Inc., our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even if advised of their possibility. Because some states or jurisdictions do not allow the exclusion or the limitation of liability for consequential or incidental damages, in such states or jurisdictions, our liability shall be limited to the maximum extent permitted by law. 
                
                SECTION 14 - INDEMNIFICATION 
                You agree to indemnify, defend and hold Fanveler Inc. and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party. 
                
                SECTION 15 - SEVERABILITY 
                In the event that any provision of these Terms of Service is determined to be unlawful, void or unenforceable, such provision shall nonetheless be enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these Terms of Service, such determination shall not affect the validity and enforceability of any other remaining provisions. 
                
                SECTION 16 - TERMINATION 
                The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes. 
                
                These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site. 
                
                If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof). 
                
                SECTION 17 - ENTIRE AGREEMENT 
                The failure of us to exercise or enforce any right or provision of these Terms of Service shall not constitute a waiver of such right or provision. 
                
                These Terms of Service and any policies or operating rules posted by us on this site or in respect to The Service constitutes the entire agreement and understanding between you and us and govern your use of the Service, superseding any prior or contemporaneous agreements, communications and proposals, whether oral or written, between you and us (including, but not limited to, any prior versions of the Terms of Service). 
                
                Any ambiguities in the interpretation of these Terms of Service shall not be construed against the drafting party. 
                
                SECTION 18 - GOVERNING LAW 
                These Terms of Service and any separate agreements whereby we provide you Services shall be governed by and construed in accordance with the laws of 상도로61길55 Dongjak District, Seoul, 11, 07027, Korea, Republic of. 
                
                SECTION 19 - CHANGES TO TERMS OF SERVICE 
                You can review the most current version of the Terms of Service at any time at this page. 
                
                We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes. 
                
                SECTION 20 - CONTACT INFORMATION 
                Questions about the Terms of Service should be sent to us at support@fanveler.com.
        `;

        return (
                <TxtDiv>
                        <Txt>{TermsEng}</Txt>
                </TxtDiv>
        );
}

export default {
        TermsOfUseKor,
        TermsOfUseEng
};