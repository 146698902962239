import PlatformStyles from '../../styles/PlatformStyles';

import { useNavigate } from 'react-router-dom';
import gameHiddenLogo from '../../assets/svg/gameHiddenLogo.svg';
import gameLocationImg from '../../assets/svg/gameLocation_mannyeon.svg';
import gameLogoImg from '../../assets/svg/gameLogo_MNOff4.svg';

import gameType from '../../assets/svg/Offline.svg';

import gameIconLevel from '../../assets/svg/levelDfkltyIcon.svg';
import gameIconPlyTm from '../../assets/svg/playTimeIcon.svg';
import gameIconType from '../../assets/svg/typeIcon.svg';
import gameIconStartPt from '../../assets/svg/startingPointIcon.svg';

import StarFull from '../../assets/svg/starFull.svg';
import StarHalf from '../../assets/svg/starHalf.svg';
import StarEmpty from '../../assets/svg/starEmpty.svg';

import gameStartPtImg from '../../assets/svg/startingPointSVG_MNOff4.svg'

import gameBtmBtnLeft from '../../assets/svg/bottomButtonLeft.svg';
import gameBtmBtnHome from '../../assets/svg/bottomButtonHome.svg';
import gameBtmBtnRayt from '../../assets/svg/bottomButtonRight.svg';

const {
    Container, 
    GameLogoDiv,
    GameLogoImg,
    HomeDiv,
    HomeImgDiv,
    GameLocationImg,
    GameHomeImg,
    GameTitleContDiv,
    GameTitleDiv,
    GameTitleText,
    GameType,
    GameTypeImg,
    GameInfoDiv,
    GameInfoIconDiv,
    GameInfoIcon,
    GameInfoTxtDiv,
    GameInfoTxtDiv23,
    GameInfoTxt,
    GameLvl,
    GameLvlStars,
    StartingPtTxt,
    TobeUpdatedDiv,
    GraySpan,
    BottomButtons,
    BottomBtnLeft,
    BottomBtnHome,
    BottomBtnRayt,
    BtnImg,
    ToBeUpdatedTxt, 
    EmptyDiv
} = PlatformStyles;

function MNOff4() {

    const navigate = useNavigate();

    return (
        <>
            <Container>
                <GameLogoDiv>
                    <GameLogoImg src={gameHiddenLogo} />
                </GameLogoDiv>
                <HomeDiv>
                    <HomeImgDiv><GameLocationImg src={gameLocationImg} /></HomeImgDiv>
                    
                    <HomeImgDiv><GameHomeImg src={gameLogoImg} /></HomeImgDiv>

                    <GameTitleContDiv>
                        <GameTitleDiv>
                            <GameTitleText>수상한 뒤틀림</GameTitleText>
                            <GameTitleText><GraySpan>Weird Twist</GraySpan></GameTitleText>
                        </GameTitleDiv>
                        <GameType></GameType>
                        <GameType><GameTypeImg src={gameType} /></GameType>
                    </GameTitleContDiv>

                    <GameInfoDiv>
                        <GameInfoIconDiv><GameInfoIcon src={gameIconLevel} /></GameInfoIconDiv>
                        <GameInfoTxtDiv>
                            <GameInfoTxt>난이도 <GraySpan>Level</GraySpan></GameInfoTxt>
                        </GameInfoTxtDiv>
                        <GameLvl>
                            <GameLvlStars src={StarFull} />
                            <GameLvlStars src={StarFull} />
                            <GameLvlStars src={StarFull} />
                            <GameLvlStars src={StarHalf} />
                            <GameLvlStars src={StarEmpty} />
                        </GameLvl>
                    </GameInfoDiv>
                    <GameInfoDiv>
                        <GameInfoIconDiv><GameInfoIcon src={gameIconPlyTm} /></GameInfoIconDiv>
                        <GameInfoTxtDiv>
                            <GameInfoTxt>소요시간 <GraySpan>Play Time</GraySpan></GameInfoTxt>
                        </GameInfoTxtDiv>
                        <GameLvl>
                            <GameInfoTxt>2시간 <GraySpan>hr</GraySpan></GameInfoTxt></GameLvl>
                    </GameInfoDiv>
                    <GameInfoDiv>
                        <GameInfoIconDiv><GameInfoIcon src={gameIconType} /></GameInfoIconDiv>
                        <GameInfoTxtDiv>
                            <GameInfoTxt>퀘스트 유형 <GraySpan>Type</GraySpan></GameInfoTxt>
                        </GameInfoTxtDiv>
                        <GameLvl>
                            <GameInfoTxt>야외 <GraySpan>Outdoor</GraySpan></GameInfoTxt>
                        </GameLvl>
                    </GameInfoDiv>
                    <GameInfoDiv>
                        <GameInfoIconDiv><GameInfoIcon src={gameIconStartPt} /></GameInfoIconDiv>
                        <GameInfoTxtDiv>
                            <GameInfoTxt>시작점<GraySpan>Starting Point</GraySpan></GameInfoTxt>
                        </GameInfoTxtDiv>
                        <GameLvl>
                            <GameInfoTxt></GameInfoTxt>
                        </GameLvl>
                    </GameInfoDiv>

                    <GameInfoDiv>
                        <GameInfoIconDiv></GameInfoIconDiv>
                        <GameInfoTxtDiv23>
                            <StartingPtTxt>한밭수목원 엑스포 시민광장 식수대</StartingPtTxt>
                            <StartingPtTxt><GraySpan>Hanbat Arboretum Expo Civic Square</GraySpan></StartingPtTxt>
                        </GameInfoTxtDiv23>
                    </GameInfoDiv>
                    <GameInfoDiv>
                        <GameInfoIconDiv></GameInfoIconDiv>
                        <GameInfoTxtDiv>
                            <StartingPtTxt><GraySpan>Drinking Fountain</GraySpan></StartingPtTxt>
                        </GameInfoTxtDiv>
                        <GameLvl>
                        <GameInfoIcon src={gameStartPtImg}/>
                            {/* <GameInfoTxt><GameInfoIcon src={gameStartPtImg}/></GameInfoTxt> */}
                        </GameLvl>
                    </GameInfoDiv>

                    <TobeUpdatedDiv><ToBeUpdatedTxt /></TobeUpdatedDiv>

                    <EmptyDiv ht="130px" />
                    
                    <BottomButtons pb="15px">
                        <BottomBtnLeft>
                            <BtnImg 
                                src={gameBtmBtnLeft} 
                                onClick={() => navigate('/mnOff3')}
                            />
                        </BottomBtnLeft>
                        <BottomBtnHome>
                            <BtnImg  
                                src={gameBtmBtnHome} 
                                onClick={() => navigate('/allPages')}
                            />
                        </BottomBtnHome>
                        <BottomBtnRayt>
                            <BtnImg 
                                src={gameBtmBtnRayt} 
                                onClick={() => navigate('/ryunge')}
                            />
                        </BottomBtnRayt>
                    </BottomButtons>
                </HomeDiv>
            </Container>
        </>
    );
};

export default MNOff4;