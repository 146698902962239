import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";

import Contact from "./Misc/Contact";
import PlatformStyles from "../styles/PlatformStyles";
import { getCookie, setCookie } from "../utils/cookies";
import userApi from "../services/userApi";

import gameHiddenLogo from "../assets/svg/gameHiddenLogo.svg";
import gameLoginImg from "../assets/svg/gameLoginImg.svg";
import btnLoginGray from "../assets/svg/button_login_gray.svg";
import btnLoginBlue from "../assets/svg/button_login_blue.svg";
import btnLoading from "../assets/svg/loading_260.svg";
import gameBtmBtnHome from "../assets/svg/bottomButtonHome.svg";

const bcrypt = require("bcryptjs");
const crypto = require("crypto-js");

const {
  Container,
  GameLogoDiv,
  GameLogoImg,
  HomeDiv,
  HomeImgDiv,
  GameLocationImg,
  BottomBtnHome,
  BtnImg,
  MiscellaneousDiv,
  BottomAnsDiv,
  Padding25,
  NotMatchingDiv,
  NotMatchingTxtDiv,
  NotMatchingTxt,
  BtnDiv,
  BtnTxt,
  InputDiv,
  InputForm,
} = PlatformStyles;

function Login() {
  const gameData = [
    {
      qId: "YUEO02ONAB",
      navTo: "ryungePrequal",
      webpage: "https://eoonline.hiddenplace.io/",
    },
    {
      qId: "SUMN01ONAC",
      navTo: "mnOnline",
      webpage: "https://mnhero.hiddenplace.io/",
    },
    {
      qId: "SUMN02OFAD",
      navTo: "mnOff1",
      webpage: "https://mnoffline.hiddenplace.io/",
    },
    {
      qId: "SUMN03OFAE",
      navTo: "mnOff2",
      webpage: "https://mnoffline2.hiddenplace.io/",
    },
    {
      qId: "JNSC02OFAI",
      navTo: "basecamp",
      webpage: "https://develop.hiddenplace.io/",
    },
  ];

  const [id, setId] = useState("");
  const [pwd, setPwd] = useState("");

  const [isInfoInput, setIsInfoInput] = useState(false);

  const [idPwdNotMatch, setIdPwdNotMatch] = useState(false);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const inputId = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredCode = event.target.value;
    setId(enteredCode);
  };

  const inputPwd = (event: React.ChangeEvent<HTMLInputElement>) => {
    const enteredCode = event.target.value;
    setPwd(enteredCode);
  };

  const { refetch: checkUserAndLogin } = useQuery(
    "query-userDB-by-user_id",
    async () => {
      return await userApi.getUserById(id.toLowerCase());
    },
    {
      enabled: false,
      retry: 1,
      onSuccess: (res) => {
        if (res.Item?.user_id && bcrypt.compareSync(pwd, res.Item?.password)) {
          const encode = crypto.AES.encrypt(id.toLowerCase(), "FANVELER");

          setCookie("USER_ID", `${encode}`, {
            domain: ".hiddenplace.io",
            path: "/",
            secure: true,
          });

          sessionStorage.setItem("LOGGED-IN", "YES");

          const getWhatP = sessionStorage.getItem("PLAY-NOW-CLICK");

          if (getWhatP === "YES") {
            let qCodeE = false;

            if (res.Item?.quests_code) {
              for (let element of res.Item?.quests_code) {
                if (element.slice(0, 2) === getCookie("QUEST_ID").slice(-2)) {
                  const encode = crypto.AES.encrypt(
                    element.toUpperCase(),
                    getCookie("QUEST_ID") + "KY"
                  );

                  setCookie("QUEST_CODE", `${encode}`, {
                    domain: ".hiddenplace.io",
                    path: "/",
                    secure: true,
                  });

                  qCodeE = true;

                  gameData.map((item) => {
                    if (item.qId === getCookie("QUEST_ID")) {
                      window.location.href = `${item.webpage}`;
                    }
                  });

                  break;
                }
              }
            }

            if (!qCodeE) {
              gameData.map((item) => {
                if (item.qId === getCookie("QUEST_ID")) {
                  sessionStorage.setItem(`${item.qId.slice(-2)}-PAGE`, "NCF");
                  navigate(`/${item.navTo}`);
                }
              });
            }
          } else {
            navigate("/allPages");
          }

          setIdPwdNotMatch(false);
        } else {
          setLoading(false);
          setIdPwdNotMatch(true);
        }
      },
      onError: (err: any) => {
        console.log(err.response?.data || err);
        navigate("/");
      },
    }
  );

  useEffect(() => {
    if (id.length > 0 && pwd.length > 0) setIsInfoInput(true);
    else setIsInfoInput(false);
  }, [id, pwd]);

  return (
    <>
      <Container>
        <GameLogoDiv>
          <GameLogoImg src={gameHiddenLogo} />
        </GameLogoDiv>
        <HomeDiv>
          <HomeImgDiv>
            <GameLocationImg src={gameLoginImg} />
          </HomeImgDiv>
          <Padding25 />
          <BottomAnsDiv>
            <InputDiv>
              <InputForm
                type="text"
                onChange={inputId}
                placeholder="아이디 ID"
                value={id}
                minLength={6}
                onClick={() => {
                  setIdPwdNotMatch(false);
                }}
              />
            </InputDiv>
          </BottomAnsDiv>
          <BottomAnsDiv>
            <InputDiv>
              <InputForm
                type="password"
                onChange={inputPwd}
                placeholder="비밀번호 PW"
                value={pwd}
                minLength={8}
                onClick={() => {
                  setIdPwdNotMatch(false);
                }}
              />
            </InputDiv>
          </BottomAnsDiv>

          {!isInfoInput && (
            <BottomAnsDiv>
              <BtnImg src={btnLoginGray} />
            </BottomAnsDiv>
          )}

          {isInfoInput && (
            <BottomAnsDiv>
              {!loading && (
                <BtnImg
                  src={btnLoginBlue}
                  onClick={() => {
                    setLoading(true);
                    checkUserAndLogin();
                  }}
                />
              )}
              {loading && <BtnImg src={btnLoading} />}
            </BottomAnsDiv>
          )}

          <NotMatchingDiv>
            {idPwdNotMatch && (
              <NotMatchingTxtDiv>
                <NotMatchingTxt clr="#47444B">
                  아이디/비밀번호 불일치
                </NotMatchingTxt>
              </NotMatchingTxtDiv>
            )}
            {idPwdNotMatch && (
              <NotMatchingTxtDiv>
                <NotMatchingTxt clr="#A0AEC0">Check ID/PW</NotMatchingTxt>
              </NotMatchingTxtDiv>
            )}
          </NotMatchingDiv>

          <BtnDiv>
            <a rel="noreferrer" href="/signup">
              <BtnTxt>회원가입 Sign Up</BtnTxt>
            </a>
          </BtnDiv>
          <BtnDiv>
            <a rel="noreferrer" href="/findIdPwd">
              <BtnTxt>계정 찾기 Find ID/PW</BtnTxt>
            </a>
          </BtnDiv>
          <BtnDiv>
            <a rel="noreferrer" href="#" onClick={() => navigate(-1)}>
              <BtnTxt>뒤로가기 Back</BtnTxt>
            </a>
          </BtnDiv>
          <MiscellaneousDiv mt="60px">
            <Contact />
          </MiscellaneousDiv>
          <BottomBtnHome>
            <BtnImg
              src={gameBtmBtnHome}
              onClick={() => navigate("/allPages")}
            />
          </BottomBtnHome>
          <Padding25 />
        </HomeDiv>
      </Container>
    </>
  );
}

export default Login;
