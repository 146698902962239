import React from 'react';
import styled from 'styled-components';
import Router from './Router';

const Container = styled.div`
  max-width: 100%;
  height: 100%;
  // background-color: '#2C5282';
`;

function SmallScreen() {
  return (
    <>
      <Container>
        <Router />
      </Container>
    </>
  );
}

export default SmallScreen;