import styled from 'styled-components';

const TxtDiv = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 35px 17% 25px 17%;
  white-space: pre-line;
  overflow-x: hidden;
  overflow-y: auto;
  word-break: break-word;
`;

const Txt = styled.h1`
   font-family: 'Noto400';
   font-size: 13px;
   line-height: 14px;
   color: #000000;
   text-align: justify;
`;

const PrivacyPolicyKor = () => {
    const PrivacyKor = `
        <주식회사 팬블러>(이하 ‘회사’)는 개인정보보호법에 따라 이용자의 개인정보 보호 및 권익을 보호하고 개인정보와 관련한 이용자의 고충을 원활하게 처리할 수 있도록 다음과 같은 처리방침을 두고 있습니다. 회사는 개인정보처리방침을 개정하는 경우 웹사이트 공지사항(또는 개별공지)을 통하여 공지할 것입니다. 본 방침은부터 2021년 1월 1일부터 적용됩니다. 

        [개인정보의 수집에 대한 동의] 
        
        회사는 고객님께서 개인정보처리방침 또는 이용약관의 내용에 「동의한다」 또는 「동의하지 않는다」버튼을 클릭할 수 있는 절차를 마련하고 있으며,「동의한다」 버튼을 클릭하면 개인정보 수집 에 동의한 것으로 봅니다. 
        
        아동의 개인정보보호 
        회사는 만14세 미만 아동의 개인정보를 수집하는 경우 법정대리인의 동의를 받습니다. 만14세 미만 아동의 법정대리인은 아동의 개인정보의 열람, 정정, 동의철회를 요청할 수 있으며, 이러한 요청이 있을경우 회사는 지체없이 필요한 조치를 취합니다. 
        
        [개인정보의 수집 및 이용 목적]
        
        회사는 개인정보를 다음의 목적을 위해 처리합니다. 처리한 개인정보는 다음의 목적이외의 용도로는 사용되지 않으며 이용 목적이 변경될 시에는 사전동의를 구할 예정입니다. 
        
        가.홈페이지 회원가입 및 관리 
        회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 제한적 본인확인제 시행에 따른 본인확인, 서비스 부정이용 방지, 만14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, 각종 고지·통지, 고충처리, 분쟁 조정을 위한 기록 보존 등을 목적으로 개인정보를 처리합니다. 
        
        나.재화 또는 서비스 제공 
        물품배송, 서비스 제공, 청구서 발송, 콘텐츠 제공, 맞춤 서비스 제공, 본인인증, 연령인증, 요금결제·정산 등을 목적으로 개인정보를 처리합니다. 
        
        다.마케팅 및 광고에의 활용 
        신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 , 인구통계학적 특성에 따른 서비스 제공 및 광고 게재 , 서비스의 유효성 확인, 접속빈도 파악 또는 회원의 서비스 이용에 대한 통계 등을 목적으로 개인정보를 처리합니다. 
        
        [개인정보 수집 항목]
        
        회사는 기본적인 서비스 제공을 위한 필수 정보만을 수집하고 있으며 고객 각각의 기호와 필요에 맞는 서비스를 제공하기 위하여 정보 수집 때 별도 동의를 얻고 있습니다. 선택 정보를 입력하지 않아도 서비스 이용에 제한은 없습니다. 
        
        회사는 다음과 같이 개인정보를 수집하여 이용합니다. 
        
        개인정보 항목 : 이메일, 휴대전화번호, 자택주소, 자택전화번호, 비밀번호 질문과 답, 비밀번호, 로그인ID, 성별, 생년월일, 이름, 회사전화번호, 직책, 부서, 회사명, 취미, 신용카드정보, 은행계좌정보, 서비스 이용 기록, 접속 로그, 쿠키, 접속 IP 정보, 결제기록 
        
        수집방법 : 홈페이지, 서면양식, 전화/팩스, 경품행사, 배송요청, 제휴사로부터 제공 받음, 생성정보 수집 툴을 통한 수집, Google Analytics 
        
        [개인정보의 보유 및 이용기간]
        
        회사는 고객의 개인정보를 고지 및 동의받은 기간 동안 보유 및 이용합니다. 개인정보의 수집 및 이용목적 달성, 보유기간 만료, 회원의 수집 및 이용 동의 철회시 수집된 개인정보는 열람하거나 이용할 수 없도록 파기 처리합니다. 
        
        회사는 상법 및 ‘전자상거래등에서 소비자보호에 관한 법률’, 전자금융거래법, 여신전문금융업법 국세기본법, 법인세법, 부가가치세법 등 관련 법령의 규정에 따라 다음과 같이 거래 관련 권리 의무 관계를 확인하기 위하여 개인정보를 일정기간 보유할 수 있으며, 이 때 보유하는 개인정보의 열람 및 이용은 해당 사유로 한정하며, 미리 보유목적, 기간 및 보유하는 개인정보 항목을 분명히 밝혀야 합니다. 
        
        보유기간 : 5년
        
        관련법령 : 
        1)신용정보의 수집/처리 및 이용 등에 관한 기록 : 3년 
        2)소비자의 불만 또는 분쟁처리에 관한 기록 : 3년 
        3)대금결제 및 재화 등의 공급에 관한 기록 : 5년 
        4)계약 또는 청약철회 등에 관한 기록 : 5년 
        5)표시/광고에 관한 기록 : 6개월 
        
        단, 회사 이용계약(이용약관)에 의해 이용계약이 존속중인 회원(탈퇴하지 아니한 회원)의 경우 보관기간은 보존의무기간 이상 보관할 수 있으며, 해당 기간이 경과된 기록에 대해서는 고객의 동의 철회 요청이 있는 경우 파기합니다. 
        
        고객님의 동의를 얻어 보유하고 있는 거래정보를 고객님께서 열람하고자 요구할 때에는 바로 열람할 수 있도록 조치합니다. 
        
        회원에서 탈퇴한 후 회원 재가입, 임의해지 등을 반복적으로 행하여 회사가 제공하는 할인쿠폰, 이벤트 혜택 등으로 경제상의 이익을 취하거나 이 과정에서 명의를 무단으로 사용하는 편 법과 불법행위를 하는 회원을 차단 하고자 회원 탈퇴 후 1개월 동안(업체회원의 경우 2개월) 회원의 성명, 주민등록번호(또는 CI), DI, 아이디(ID), 비밀번호(Password), 이메일(E-mail), 연락처 정보를 보관하며, 로그기록, 접속아이피(IP) 정보는 12개월간 보관합니다. 
        
        회원이 1년 간 서비스 이용기록이 없는 경우, 「정보통 신망 이용촉진 및 정보보호등에 관한 법률」제 29조에 근거하여 회원에게 사전 통지하고 별도로 분리하여 저장합니다. 단, 상기 나.항 기재 법률 등 관계 법령의 규정에 의하여 보존이 필요할 경우 관계 법령에서 규정한 기간 동안 보관합니다. 
        
        [수집한 개인정보의 이용 및 제3자 제공]
        
        회사는 고객님의 개인정보를 가입신청서, 서비스이용약관, 「개인정보처리방침」의「개인정보의 수집 및 이용목적」에서 알린 범위 내에서 사용하며, 이 범위를 초과하여 이용하거나 타인 또는 다른 기업 · 기관에 제공하지 않습니다. 단, 고객의 동의가 있거나 법령에 의하여 정해진 절차에 따라 정보를 요청받은 경우는 예외로 하며, 이 경우 주의를 기울여 개인정보를 이용 및 제공할 수 있습니다. 
        회사는 계약의 이행을 위하여 최소한의 개인정보만 제공하고 있으며, 개인정보를 제3자에게 제공해야 하는 경우 사전에 이용자에게 해당 사실을 알리고 동의를 구할 수 있습니다. 다만 다음의 경우는 예외로 하고 있습니다. 
        
        서비스 제공에 따른 요금정산을 위해 필요한 경우 
        법령의 규정에 의한 경우 
        수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우 
        회사에서 제공하는 서비스를 통해 거래가 성사되었을 때에는 쌍방 당사자간의 물품거래와 배송에 관련한 정보를 필요한 범위 내에서 쌍방당사자 및 배송업체에게 제공합니다. 
        
        개인정보 제공현황은 제휴관계의 개시 또는 종료에 따라 변동될 수 있으며 개인정보 제공과 함께 목록에 게시됩니다. 제휴관계까 종료되면 개인정보의 파기와 함께 목록에서 삭제됩니다. 
        
        [개인정보처리 위탁]
        ①회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다. 
        1. <추가:위탁사무명> 
        위탁받는 자 (수탁자) : Paypal, 네이버 페이, DHL, 카카오 페이, 대한통운 
        위탁하는 업무의 내용 : 구매 및 요금 결제, 물품배송 또는 청구서 등 발송, 본인인증(금융거래, 금융서비스), 요금추심, 회원제 서비스 이용에 따른 본인확인, 불만처리 등 민원처리, 고지사항 전달, 신규 서비스(제품) 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공 및 참여기회 제공 
        위탁기간 : 5년 
        ②회사는 위탁계약 체결시 개인정보 보호법 제25조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적․관리적 보호조치, 재위탁 제한, 수탁자에 대한 관리․감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다. 
        ③위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다. 
        정보주체와 법정대리인의 권리·의무 및 그 행사방법 이용자는 개인정보주체로써 다음과 같은 권리를 행사할 수 있습니다. 
        
        ①정보주체는 회사에 대해 언제든지 개인정보 열람,정정,삭제,처리정지 요구 등의 권리를 행사할 수 있습니다. 
        ②제1항에 따른 권리 행사는 회사에 대해 개인정보 보호법 시행령 제41조제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며 회사는 이에 대해 지체 없이 조치하겠습니다. 
        ③제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다. 
        ④개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조 제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다. 
        ⑤개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다. 
        ⑥회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다. 
        
        [개인정보의 파기]
        회사는 원칙적으로 개인정보 처리목적이 달성된 경우에는 지체없이 해당 개인정보를 파기합니다. 파기의 절차, 기한 및 방법은 다음과 같습니다. 
        
        파기절차 
        이용자가 입력한 정보는 목적 달성 후 별도의 DB에 옮겨져(종이의 경우 별도의 서류) 내부 방침 및 기타 관련 법령에 따라 일정기간 저장된 후 혹은 즉시 파기됩니다. 이 때, DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다. 
        파기기한 
        이용자의 개인정보는 개인정보의 보유기간이 경과된 경우에는 보유기간의 종료일로부터 5일 이내에, 개인정보의 처리 목적 달성, 해당 서비스의 폐지, 사업의 종료 등 그 개인정보가 불필요하게 되었을 때에는 개인정보의 처리가 불필요한 것으로 인정되는 날로부터 5일 이내에 그 개인정보를 파기합니다. 
        파기방법 
        전자적 파일 형태의 정보는 기록을 재생할 수 없는 기술적 방법을 사용합니다. 
        종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다. 
        
        [개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항]
        
        ①회사는 개별적인 맞춤서비스를 제공하기 위해 이용정보를 저장하고 수시로 불러오는 ‘쿠기(cookie)’를 사용합니다. 
        ②쿠키는 웹사이트를 운영하는데 이용되는 서버(http)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터내의 하드디스크에 저장되기도 합니다. 
        가.쿠키의 사용 목적 : 이용자가 방문한 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 등을 파악하여 이용자에게 최적화된 정보 제공을 위해 사용됩니다. 
        나.쿠키의 설치·운영 및 거부 : 웹브라우저 상단의 도구>인터넷 옵션>개인정보 메뉴의 옵션 설정을 통해 쿠키 저장을 거부 할 수 있습니다. 
        다.쿠키 저장을 거부할 경우 맞춤형 서비스 이용에 어려움이 발생할 수 있습니다. 
        
        [개인정보 보호책임자 작성]
        
        ①회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다. 
        개인정보 보호책임자 
        성명 :배현혜 
        직책 :대표 
        직급 :CEO 
        연락처 :02-6203-7227, chhb@fanveler.com 
        ②정보주체께서는 회사의 서비스(또는 사업)을 이용하시면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의하실 수 있습니다. 회사는 정보주체의 문의에 대해 지체 없이 답변 및 처리해드릴 것입니다. 
        
        [개인정보 처리방침 변경]
        
        ①이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다. 
        
        [개인정보의 안전성 확보 조치]
        
        회사는 개인정보보호법 제29조에 따라 다음과 같이 안전성 확보에 필요한 기술적/관리적 및 물리적 조치를 하고 있습니다. 
        
        1.정기적인 자체 감사 실시 
        개인정보 취급 관련 안정성 확보를 위해 정기적(분기 1회)으로 자체 감사를 실시하고 있습니다. 
        2.개인정보 취급 직원의 최소화 및 교육 
        개인정보를 취급하는 직원을 지정하고 담당자에 한정시켜 최소화 하여 개인정보를 관리하는 대책을 시행하고 있습니다. 
        3.내부관리계획의 수립 및 시행 
        개인정보의 안전한 처리를 위하여 내부관리계획을 수립하고 시행하고 있습니다. 
        4.개인정보의 암호화 
        이용자의 개인정보는 비밀번호는 암호화 되어 저장 및 관리되고 있어, 본인만이 알 수 있으며 중요한 데이터는 파일 및 전송 데이터를 암호화 하거나 파일 잠금 기능을 사용하는 등의 별도 보안기능을 사용하고 있습니다. 
        5.접속기록의 보관 및 위변조 방지 
        개인정보처리시스템에 접속한 기록을 최소 6개월 이상 보관, 관리하고 있으며, 접속 기록이 위변조 및 도난, 분실되지 않도록 보안기능 사용하고 있습니다. 
        6.개인정보에 대한 접근 제한 
        개인정보를 처리하는 데이터베이스시스템에 대한 접근권한의 부여,변경,말소를 통하여 개인정보에 대한 접근통제를 위하여 필요한 조치를 하고 있으며 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있습니다. 
        7.문서보안을 위한 잠금장치 사용 
        개인정보가 포함된 서류, 보조저장매체 등을 잠금장치가 있는 안전한 장소에 보관하고 있습니다. 
        8.비인가자에 대한 출입 통제 
        개인정보를 보관하고 있는 물리적 보관 장소를 별도로 두고 이에 대해 출입통제 절차를 수립, 운영하고 있습니다.    
    `;
    
    return (
        <TxtDiv>
            <Txt>{PrivacyKor}</Txt>
        </TxtDiv> 
    );
};

const PrivacyPolicyEng = () => {
    const PrivacyEng = `
        This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from www.hiddenplace.io (the “Site”). 

        PERSONAL INFORMATION WE COLLECT 
        When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.” 
        
        We collect Device Information using the following technologies: 
        
        “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org. 
        “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps. 
        “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site. 
        “Google analytics” include a block of JavaScript code on website pages. The JavaScript code references a JavaScript file which executes the tracking operation for analytics. Sources that Google analytics uses are “HTTP request of the user,” “Browser/system information,” and “First-party cookies.” 
        
        Additionally, when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, preferred language, billing address, shipping address, payment information (including credit card numbers and any other required information), email address, and phone number. We refer to this information as “Order Information.” 
        
        When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information. 
        
        HOW DO WE USE YOUR PERSONAL INFORMATION? 
        We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations). Additionally, we use this Order Information to: 
        
        Communicate with you; 
        
        Screen our orders for potential risk or fraud; and 
        
        When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services. 
        
        We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns). 
        
        SHARING YOUR PERSONAL INFORMATION 
        We share your Personal Information with third parties to help us use your Personal Information, as described above. We use Google Analytics to help us understand how our customers use the Site--you can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout. 
        
        Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights. 
        
        BEHAVIOURAL ADVERTISING 
        As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you. For more information about how targeted advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work. 
        
        You can opt out of targeted advertising by: 
        
        INCLUDE OPT-OUT LINKS FROM WHICHEVER SERVICES BEING USED. 
        COMMON LINKS INCLUDE: 
        
        FACEBOOK - https://www.facebook.com/settings/?tab=ads 
        GOOGLE - https://www.google.com/settings/ads/anonymous 
        BING - https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads 
        Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at: http://optout.aboutads.info/. 
        
        DO NOT TRACK 
        Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser. 
        
        YOUR RIGHTS 
        If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below. 
        
        Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site), or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States. 
        
        DATA RETENTION 
        When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information. 
        
        CHANGES 
        We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons. 
        
        CONTACT US 
        For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at support@fanveler.com or by mail using the details provided below:
    `;

    return (
        <TxtDiv>
            <Txt>{PrivacyEng}</Txt>
        </TxtDiv>
        
    );
    
};

export default {
    PrivacyPolicyKor,
    PrivacyPolicyEng,
};