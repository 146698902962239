import axios from 'axios';

const questCodesAPI = axios.create({
    baseURL: 'https://5or5if5vlf.execute-api.ap-northeast-2.amazonaws.com',
    headers: {
        'Content-type': 'application/json'
    }
});

const deleteQuestCode = async (quest_code: string) => {
    const response = await questCodesAPI.delete<any>(`/quest_codes/${quest_code}`);
    return response.data;
};

const getQuestCode = async (quest_code: string) => {
    const response = await questCodesAPI.get(`/quest_codes/${quest_code}`);
    return response.data;
};

const putQuestCode = async ({quest_code}: any) => {
    const response = await questCodesAPI.put(`/quest_codes`, {quest_code});
    return response.data;
};

const putQuestId = async (quest_code: string, {user_id}: any) => {
    const response = await questCodesAPI.put(`/usr_id/${quest_code}`, {user_id});
    return response.data;
}

const putCodeTaken = async (quest_code: string, {taken}: any) => {
    const response = await questCodesAPI.put(`/code_taken/${quest_code}`, {taken});
    return response.data;
}

const questCodesApi = {
    deleteQuestCode,
    getQuestCode,
    putQuestCode,
    putQuestId,
    putCodeTaken
};

export default questCodesApi;