import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Router from './Router';

import { useLocation } from 'react-router-dom';
const Wrapper = styled.div<{ bg: string }>`
  max-width: 100%;
  min-height: 100vh;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.bg};
`;

const Container = styled.div`
  width: 480px;
  height: 100%;  
`;

function BigScreen() {

  const getScreen = sessionStorage.getItem("SCREEN-NUM");
  const bg = "#082631";

  return (

    <>
      <Wrapper bg={bg}>
        <Container>
          <Router />
        </Container>
      </Wrapper>

    </>
  );
}

export default BigScreen;