import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { useNavigate, useLocation } from 'react-router-dom';
import homeHiddenLogo from '../assets/svg/homeHiddenLogo.svg';
import homeNextButton from '../assets/svg/homeNextButton.svg';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #082631;
  min-height: 100vh;
  max-height: 812px;
  padding-left: 20px;
  padding-right: 20px;
  overflow-x: hidden;
`;

const HomeDiv = styled.div`
  top: 120px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 520px;
  border-radius: 25px;
  background-color: #FFFFFF
`;

const HomeImage = styled.img`
  position: absolute;
  max-width: 100%;
  height: auto;
`;

const NextBtn = styled.img`
  position: absolute;
  max-width: 20%;
  height: auto;
  bottom: 30px;
`;

function HomeScreen() {
  const navigate = useNavigate();

  return (
    <>
      <Container>
        <HomeDiv onClick={() => {
          navigate('/allPages')
        }}>
          <HomeImage src={homeHiddenLogo} />
          <NextBtn src={homeNextButton} />
        </HomeDiv>

      </Container>
    </>
  );
};

export default HomeScreen;