import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import GlobalFont from './styles/GlobalFonts';
import GlobalStyle from './styles/GlobalStyles';
import { theme } from './styles/theme';

import BigScreen from './pages/BigScreen';
import SmallScreen from './pages/SmallScreen';

function App() {
  const isBigScreen = useMediaQuery({
    query: '(min-width: 480px)',
  });
  
  const queryClient = new QueryClient()

  return (
      <>
      <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
          <GlobalFont />
          <GlobalStyle />
          {isBigScreen ? <BigScreen /> : <SmallScreen />}
      </ThemeProvider>
      </QueryClientProvider>
        
      </>
  );
}

export default App;
