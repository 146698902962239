import PlatformStyles from "../../styles/PlatformStyles";

import { useNavigate } from "react-router-dom";
import gameHiddenLogo from "../../assets/svg/gameHiddenLogo.svg";
import gameLocationImg from "../../assets/svg/gameLocation_Seochon.svg";
import gameLogoImg from "../../assets/svg/Thisstation_abandoned_image.svg";

import gameType from "../../assets/svg/Online.svg";

import gameIconLevel from "../../assets/svg/levelDfkltyIcon.svg";
import gameIconPlyTm from "../../assets/svg/playTimeIcon.svg";
import gameIconType from "../../assets/svg/typeIcon.svg";

import playNowON from "../../assets/svg/playNowON.svg";

import StarFull from "../../assets/svg/starFull.svg";
import StarEmpty from "../../assets/svg/starEmpty.svg";

import gameBtmBtnLeft from "../../assets/svg/bottomButtonLeft.svg";
import gameBtmBtnHome from "../../assets/svg/bottomButtonHome.svg";
import gameBtmBtnRayt from "../../assets/svg/bottomButtonRight.svg";

const {
  Container,
  GameLogoDiv,
  GameLogoImg,
  HomeDiv,
  HomeImgDiv,
  GameLocationImg,
  GameHomeImg,
  GameTitleContDiv,
  GameTitleDiv,
  GameTitleText,
  GameType,
  GameTypeImg,
  GameInfoDiv,
  GameInfoIconDiv,
  GameInfoIcon,
  GameInfoTxtDiv,
  GameInfoTxtDiv23,
  GameInfoTxt,
  GameLvl,
  GameLvlStars,
  StartingPtTxt,
  TobeUpdatedDiv,
  GraySpan,
  BottomButtons,
  BottomBtnLeft,
  BottomBtnHome,
  BottomBtnRayt,
  BtnImg,
  ToBeUpdatedTxt,
  EmptyDiv,
} = PlatformStyles;

function Abandoned() {
  const navigate = useNavigate();

  return (
    <Container>
      <GameLogoDiv>
        <GameLogoImg src={gameHiddenLogo} />
      </GameLogoDiv>
      <HomeDiv>
        <HomeImgDiv>
          {<GameLocationImg src={gameLocationImg} />}
        </HomeImgDiv>

        <HomeImgDiv>
          <GameHomeImg src={gameLogoImg} />
        </HomeImgDiv>

        <GameTitleContDiv>
          <GameTitleDiv>
            <GameTitleText>이번 역은.. 폐역사편</GameTitleText>
            <GameTitleText>
              <GraySpan>this station is.. abandoned</GraySpan>
            </GameTitleText>
          </GameTitleDiv>
          <GameType></GameType>
          <GameType>
            <GameTypeImg src={gameType} />
          </GameType>
        </GameTitleContDiv>

        <GameInfoDiv>
          <GameInfoIconDiv>
            <GameInfoIcon src={gameIconLevel} />
          </GameInfoIconDiv>
          <GameInfoTxtDiv>
            <GameInfoTxt>
              난이도 <GraySpan>Level</GraySpan>
            </GameInfoTxt>
          </GameInfoTxtDiv>
          <GameLvl>
            <GameLvlStars src={StarFull} />
            <GameLvlStars src={StarFull} />
            <GameLvlStars src={StarFull} />
            <GameLvlStars src={StarEmpty} />
            <GameLvlStars src={StarEmpty} />
          </GameLvl>
        </GameInfoDiv>
        <GameInfoDiv>
          <GameInfoIconDiv>
            <GameInfoIcon src={gameIconPlyTm} />
          </GameInfoIconDiv>
          <GameInfoTxtDiv>
            <GameInfoTxt>
              소요시간 <GraySpan>Play Time</GraySpan>
            </GameInfoTxt>
          </GameInfoTxtDiv>
          <GameLvl>
            <GameInfoTxt>
              1시간 <GraySpan>hr</GraySpan>
            </GameInfoTxt>
          </GameLvl>
        </GameInfoDiv>
        <GameInfoDiv>
          <GameInfoIconDiv>
            <GameInfoIcon src={gameIconType} />
          </GameInfoIconDiv>
          <GameInfoTxtDiv>
            <GameInfoTxt>
              퀘스트 유형 <GraySpan>Type</GraySpan>
            </GameInfoTxt>
          </GameInfoTxtDiv>
          <GameLvl>
            <GameInfoTxt>
              모바일 <GraySpan>Mobile</GraySpan>
            </GameInfoTxt>
          </GameLvl>
        </GameInfoDiv>

        <EmptyDiv ht="85px"></EmptyDiv>
        <TobeUpdatedDiv>
          <BtnImg src={playNowON} />
        </TobeUpdatedDiv>

        <EmptyDiv ht="150px" />

        <BottomButtons pb="15px">
          <BottomBtnLeft>
            <BtnImg src={gameBtmBtnLeft} onClick={() => navigate("/mnOff2")} />
          </BottomBtnLeft>
          <BottomBtnHome>
            <BtnImg
              src={gameBtmBtnHome}
              onClick={() => navigate("/allPages")}
            />
          </BottomBtnHome>
          <BottomBtnRayt>
            <BtnImg
              src={gameBtmBtnRayt}
              onClick={() => navigate("/basecamp")}
            />
          </BottomBtnRayt>
        </BottomButtons>
      </HomeDiv>
    </Container>
  );
}

export default Abandoned;
