import React, { useState, useEffect } from 'react';

import PlatformStyles from '../../styles/PlatformStyles';

import { useNavigate } from 'react-router-dom';
import gameHiddenLogo from '../../assets/svg/gameHiddenLogo.svg';
import gameLocationImgImg from '../../assets/svg/gameLocation_eoeun.svg';
import gameLogoImg from '../../assets/svg/gameLogo_ryungePrequal.svg';

import gameType from '../../assets/svg/Online.svg';

import gameIconLevel from '../../assets/svg/levelDfkltyIcon.svg';
import gameIconPlyTm from '../../assets/svg/playTimeIcon.svg';
import gameIconType from '../../assets/svg/typeIcon.svg';

import StarFull from '../../assets/svg/starFull.svg';
import StarEmpty from '../../assets/svg/starEmpty.svg';

import playNowOFF from '../../assets/svg/playNowOFF.svg';

import playNowON from '../../assets/svg/playNowON.svg';
import btnLoading from '../../assets/svg/loading_260.svg';
import btnLoading40 from '../../assets/svg/loading_40.svg';

import gameCodeNextOFF from '../../assets/svg/nextButtonOFF.svg';

import gameCodeNextON from '../../assets/svg/nextButtonON.svg';

import gameBtmBtnLeft from '../../assets/svg/bottomButtonLeft.svg';
import gameBtmBtnHome from '../../assets/svg/bottomButtonHome.svg';
import gameBtmBtnRayt from '../../assets/svg/bottomButtonRight.svg';

import { useQuery, useMutation } from 'react-query';

import questApi from '../../services/questApi';
import userApi from '../../services/userApi';
import questCodesApi from '../../services/questCodesApi';

import { getCookie, setCookie } from "../../utils/cookies";

const crypto = require('crypto-js');

const {
    Container,
    GameLogoDiv,
    GameLogoImg,
    HomeDiv,
    HomeImgDiv,
    GameLocationImg,
    GameHomeImg,
    GameTitleContDiv,
    GameTitleDiv,
    GameTitleText,
    GameType,
    GameTypeImg,
    GameInfoDiv,
    GameInfoIconDiv,
    GameInfoIcon,
    GameInfoTxtDiv,
    GameInfoTxt,
    GameLvl,
    GameLvlStars,
    EnterCodeTextDiv,
    GraySpan,
    BottomButtons,
    BottomBtnLeft,
    BottomBtnHome,
    BottomBtnRayt,
    BtnImg,
    BottomAnsDiv,
    InputField,
    NextBtn,
    InvalidCodeDiv,
    InvalidCodeText,
    EmptyDiv,
    TobeUpdatedDiv,
    EnterGameCodeTxt,
    InputForm
} = PlatformStyles;

const questId = 'YUEO02ONAB';

function RyungePrequal() {
    const [questCode, setQuestCode] = useState('');
    const [validCode, setValidCode] = useState(true);
    const [input, setInput] = useState(false);
    const [gotoWeb, setGotoWeb] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading40, setLoading40] = useState(false);

    const navigate = useNavigate();

    const whatPage = sessionStorage.getItem("AB-PAGE");
    const loginState = sessionStorage.getItem("LOGGED-IN");

    const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const enteredCode = event.target.value;
        setQuestCode(enteredCode);
    };

    const { refetch: checkQuestCodeAndPlay } = useQuery(
        'query-questDB-by-quest-code-and-quest-id',
        async () => {
            return await questCodesApi.getQuestCode(questCode.toUpperCase());
        },
        {
            enabled: false,
            retry: 1,
            onSuccess: (res) => {
                if (res.Item?.quest_code && questId.slice(-2) === res.Item?.quest_code.slice(0, 2) && !res.Item?.user_id) {
                    
                    setCookie('QUEST_ID', `${questId}`, {
                        domain: '.hiddenplace.io',
                        path: '/',
                        secure: true
                    });
                    
                    const encode = crypto.AES.encrypt(questCode.toUpperCase(), questId + 'KY');
                    setCookie('QUEST_CODE', `${encode}`, {
                        domain: '.hiddenplace.io',
                        path: '/',
                        secure: true
                    });

                    setValidCode(true);
                    setGotoWeb(true);
                }
                else {
                    setLoading40(false);
                    setValidCode(false);
                }
            },
            onError: (err: any) => {
                console.log(err.response?.data || err);
                navigate('/');
            }
        }
    );

    const { mutate: UpdateQuestCodes } = useMutation(
        async () => {
            return await userApi.updateUserQuestsCode(
                crypto.AES.decrypt(getCookie('USER_ID'), 'FANVELER').toString(crypto.enc.Utf8), 
                {
                    quests_code: [crypto.AES.decrypt(getCookie('QUEST_CODE'), getCookie('QUEST_ID') + 'KY').toString(crypto.enc.Utf8)]
                }
            );
        },
        {
            onSuccess: (res) => {
                PutQuestIdInQuestCodesDB();
            },
            onError: (err: any) => {
                console.log(err.response?.data || err);
                navigate('/');
            }
        },
    );

    const { mutate: PutQuestCodeAndQuestIdInQuestDB } = useMutation(
        async () => {
            return questApi.updateQuest(
                crypto.AES.decrypt(getCookie('QUEST_CODE'), getCookie('QUEST_ID') + 'KY').toString(crypto.enc.Utf8), 
                getCookie('QUEST_ID')
            );
        },
        {
            onSuccess: (res) => {
                UpdateQuestCodes();
            },
            onError: (err: any) => {
                console.log(err.response?.data || err);
                navigate('/');
            }
        },
    );

    const { mutate: PutQuestIdInQuestCodesDB } = useMutation(
        async () => {
            return questCodesApi.putQuestId(
                crypto.AES.decrypt(getCookie('QUEST_CODE'), getCookie('QUEST_ID') + 'KY').toString(crypto.enc.Utf8), 
                {
                    user_id: crypto.AES.decrypt(getCookie('USER_ID'), 'FANVELER').toString(crypto.enc.Utf8)
                }
            );
        },
        {
            onSuccess: (res) => {
                window.location.href = 'https://eoonline.hiddenplace.io/';
            },
            onError: (err: any) => {
                console.log(err.response?.data || err);
                navigate('/');
            }
        },
    );

    const { refetch: checkIfQuestCodeExists } = useQuery(
        'query-userDB-by-user_id',
        async () => {
            return await userApi.getUserById(crypto.AES.decrypt(getCookie('USER_ID'), 'FANVELER').toString(crypto.enc.Utf8));
        },
        {
            enabled: false,
            retry: 1,
            onSuccess: (res) => {
                if (res.Item?.user_id) {
                    let qCodeE = false;
                    if (res.Item?.quests_code) {
                        for (let element of res.Item?.quests_code) {
                            if (element.slice(0, 2) === questId.slice(-2)) {
                                const encode = crypto.AES.encrypt(element.toUpperCase(), questId + 'KY');

                                setCookie('QUEST_CODE', `${encode}`, {
                                    domain: '.hiddenplace.io',
                                    path: '/',
                                    secure: true
                                });

                                qCodeE = true;
                                sessionStorage.setItem("AB-PAGE", "PC");
                                window.location.href = 'https://eoonline.hiddenplace.io/';
                                break;
                            }
                        };
                    }

                    if (!qCodeE) {
                        sessionStorage.setItem("AB-PAGE", "NCF");
                    }
                        
                }
            },
            onError: (err: any) => {
                console.log(err.response?.data || err);
                navigate('/');
            }
        }
    );

    const checkIfLoggedIn = () => {
        sessionStorage.setItem("PLAY-NOW-CLICK", "YES");

        setCookie('QUEST_ID', `${questId}`, {
            domain: '.hiddenplace.io',
            // domain: '',
            path: '/',
            secure: true
        });

        if (loginState === "YES") {
            checkIfQuestCodeExists();
        }
        else {
            navigate('/login');
        }
    };

    useEffect(() => {
        if (gotoWeb === true) {
            setTimeout(() => {
                PutQuestCodeAndQuestIdInQuestDB();
            }, 250);
        }
    }, [gotoWeb]);

    return (
        <>
            <Container>
                <GameLogoDiv>
                    <GameLogoImg src={gameHiddenLogo} />
                </GameLogoDiv>
                <HomeDiv>
                    <HomeImgDiv><GameLocationImg src={gameLocationImgImg} /></HomeImgDiv>

                    <HomeImgDiv><GameHomeImg src={gameLogoImg} /></HomeImgDiv>

                    <GameTitleContDiv>
                        <GameTitleDiv>
                            <GameTitleText>장씨와 신비한 돌</GameTitleText>
                            <GameTitleText><GraySpan>Mystical Stone of Eoeun</GraySpan></GameTitleText>
                        </GameTitleDiv>
                        <GameType></GameType>
                        <GameType><GameTypeImg src={gameType} /></GameType>
                    </GameTitleContDiv>

                    <GameInfoDiv>
                        <GameInfoIconDiv><GameInfoIcon src={gameIconLevel} /></GameInfoIconDiv>
                        <GameInfoTxtDiv>
                            <GameInfoTxt>난이도 <GraySpan>Level</GraySpan></GameInfoTxt>
                        </GameInfoTxtDiv>
                        <GameLvl>
                            <GameLvlStars src={StarFull} />
                            <GameLvlStars src={StarFull} />
                            <GameLvlStars src={StarFull} />
                            <GameLvlStars src={StarEmpty} />
                            <GameLvlStars src={StarEmpty} />
                        </GameLvl>
                    </GameInfoDiv>
                    <GameInfoDiv>
                        <GameInfoIconDiv><GameInfoIcon src={gameIconPlyTm} /></GameInfoIconDiv>
                        <GameInfoTxtDiv>
                            <GameInfoTxt>소요시간 <GraySpan>Play Time</GraySpan></GameInfoTxt>
                        </GameInfoTxtDiv>
                        <GameLvl>
                            <GameInfoTxt>1시간 <GraySpan>hr</GraySpan></GameInfoTxt></GameLvl>
                    </GameInfoDiv>
                    <GameInfoDiv>
                        <GameInfoIconDiv><GameInfoIcon src={gameIconType} /></GameInfoIconDiv>
                        <GameInfoTxtDiv>
                            <GameInfoTxt>퀘스트 유형 <GraySpan>Type</GraySpan></GameInfoTxt>
                        </GameInfoTxtDiv>
                        <GameLvl>
                            <GameInfoTxt>모바일 <GraySpan>Mobile</GraySpan></GameInfoTxt>
                        </GameLvl>
                    </GameInfoDiv>

                    <EmptyDiv ht="86px"></EmptyDiv>

                    {(whatPage === 'PC' || !whatPage) && <>
                        <TobeUpdatedDiv>
                        {!loading && <BtnImg src={playNowON}
                                onClick={() => {
                                    setLoading(true)
                                    checkIfLoggedIn()
                                }}
                            />} 
                            {loading && <BtnImg src={btnLoading} />}
                        </TobeUpdatedDiv>
                        <EmptyDiv ht="145.75px"></EmptyDiv></>}

                    {(whatPage === 'NCF') && <>
                        <TobeUpdatedDiv>
                            <BtnImg src={playNowOFF} />
                        </TobeUpdatedDiv>
                        <EmptyDiv ht="10px"></EmptyDiv>
                        <EnterCodeTextDiv><EnterGameCodeTxt /></EnterCodeTextDiv>

                        <BottomAnsDiv>
                            <InputField mw="210px">
                                <InputForm
                                    type="text"
                                    onChange={inputHandler}
                                    placeholder={''}
                                    value={questCode}
                                    maxLength={10}
                                    onClick={() => {
                                        setValidCode(true);
                                        setInput(true);
                                    }}
                                />
                            </InputField>
                            {!input && <NextBtn src={gameCodeNextOFF} />}

                            {input && !loading40 && <NextBtn
                                src={gameCodeNextON}
                                onClick={() => {
                                    if (questCode.length === 8) {
                                        setLoading40(true);
                                        checkQuestCodeAndPlay();
                                    }
                                    else setValidCode(false);
                                }}
                            />}
                            {input && loading40 && <NextBtn src={btnLoading40}/>}
                        </BottomAnsDiv>

                        <InvalidCodeDiv>
                            {validCode && <>
                                <InvalidCodeText>{`\xa0`}</InvalidCodeText>
                                <EmptyDiv ht="5px"></EmptyDiv>
                                <InvalidCodeText>{`\xa0`}</InvalidCodeText>
                            </>}

                            {!validCode && <>
                                <InvalidCodeText>게임코드를 찾을 수 없습니다</InvalidCodeText>
                                <EmptyDiv ht="5px"></EmptyDiv>
                                <InvalidCodeText><GraySpan>Check your Game Code</GraySpan></InvalidCodeText>
                            </>}
                        </InvalidCodeDiv>
                        <EmptyDiv ht=".75px"></EmptyDiv>
                    </>}

                    <BottomButtons pb="15px">
                        <BottomBtnLeft>
                            <BtnImg
                                src={gameBtmBtnLeft}
                                onClick={() => navigate('/ryunge')}
                            />
                        </BottomBtnLeft>
                        <BottomBtnHome>
                            <BtnImg
                                src={gameBtmBtnHome}
                                onClick={() => navigate('/allPages')}
                            />
                        </BottomBtnHome>
                        <BottomBtnRayt>
                            <BtnImg
                                src={gameBtmBtnRayt}
                                onClick={() => navigate('/mnOnline')}
                            />
                        </BottomBtnRayt>
                    </BottomButtons>
                </HomeDiv>
            </Container>
        </>
    );
};

export default RyungePrequal;