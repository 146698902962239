import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomeScreen from './HomeScreen';
import AllPAges from './AllPages';
import Signup from './Signup';
import Login from './Login';
import FindUserID from './FindIdPwd/FindId';
import ChangePwd from './FindIdPwd/ChangePwd';
import Ryunge from './Games/Ryunge';
import RyungePrequal from './Games/RyungePrequal';
import MNOnline from './Games/MNOnline';
import MNOff1 from './Games/MNOff1';
import MNOff2 from './Games/MNOff2';
import MNOff3 from './Games/MNOff3';
import MNOff4 from './Games/MNOff4';

import ScrollToTop from './Scroll2Top';
import Abandoned from './Games/Abandoned';
import BaseCamp from './Games/Basecamp';

function Router() {
  return (
    <>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <ScrollToTop />
        <Routes>

          <Route path="/" element={<HomeScreen /> } />
          <Route path="allPages" element={<AllPAges />} />
          <Route path="signup" element={<Signup />} />
          <Route path="login" element={<Login />} />
          <Route path="findIdPwd" element={<FindUserID />} />
          <Route path="/changePassword/:user_id" element={<ChangePwd />} />
          <Route path="/ryunge" element={<Ryunge />} />
          <Route path="/ryungePrequal" element={<RyungePrequal />} />
          <Route path="/mnOnline" element={<MNOnline />} />
          <Route path="/mnOff1" element={<MNOff1 />} />
          <Route path="/mnOff2" element={<MNOff2 />} />
          <Route path="/mnOff3" element={<MNOff3 />} />
          <Route path="/mnOff4" element={<MNOff4 />} />
          <Route path="/abandoned" element={<Abandoned />} />
          <Route path="/basecamp" element={<BaseCamp />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Router;